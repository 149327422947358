<template>
  <div v-scroll-lock="isOpen">
    <div :class="[isOpen ? 'db' : 'dn']" class="modal">
      <header class="modal-header">
        <div class="relative flex-l container modal-header-inner">
          <div class="flex justify-between items-center h-100 w-100">
            <nuxt-link
              :to="localePath({ name: 'index' })"
              class="flex items-center modal-home-link"
              tabindex="0"
              @click.native="$emit('toggle', 'close')"
            >
              <img
                class="modal-logo"
                src="~/assets/images/logo-discontract-multi.svg"
                width="126"
                height="30"
                alt="Discontract logo"
              />
            </nuxt-link>
            <a
              class="db pointer modal-close"
              tabindex="0"
              href="#"
              @click.prevent="$emit('toggle', 'close')"
            >
              <svg-icon name="icon-close-small" class="icon-close-small" />
            </a>
          </div>
        </div>
      </header>
      <div class="relative container modal-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
  },
};
</script>
