const initialState = {
  details: {
    address: {
      textValue: null,
    },
    comment: {
      textValue: null,
    },
    datetime: {
      textValue: null,
    },
    estimate: {
      textValue: null,
    },
    gallery: {
      textValue: null,
      images: [],
    },
  },
};

export const state = () => ({
  id: null,
  details: {
    ...initialState.details,
  },
  skillId: null,
  workerId: null,
});

export const mutations = {
  save(state, { key, value }) {
    state.details[key] = value;
  },
  load(state, { details }) {
    state.details = details;
  },
  skill(state, { skillId }) {
    state.skillId = skillId;
  },
  pro(state, { proId }) {
    state.workerId = proId;
  },
  create(state, { id }) {
    state.id = id;
  },
  clear(s) {
    s.details = { ...initialState.details };
    s.skillId = null;
    s.workerId = null;
  },
};

export const actions = {
  section({ commit }, { key, value }) {
    // update section
    commit('save', { key, value });
  },
  load({ commit }, { details }) {
    // update section
    commit('load', { details });
  },
  skill({ commit }, { skillId }) {
    commit('skill', { skillId });
  },
  pro({ commit }, { proId }) {
    commit('pro', { proId });
  },
  create({ commit }, { id }) {
    commit('create', { id });
  },
  clear({ commit }) {
    commit('clear');
  },
};
