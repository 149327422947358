import dayjs from 'dayjs'

import 'dayjs/locale/en'
import 'dayjs/locale/lt'
import 'dayjs/locale/ru'

dayjs.extend(require('dayjs/plugin/localizedFormat'))
dayjs.extend(require('dayjs/plugin/calendar'))

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
