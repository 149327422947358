import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/storage';

// const app = !firebase.apps.length
//   ? firebase.initializeApp({ projectId: 'discontract-prod' })
//   : firebase.app();

// export const db = app.firestore();

export default (context, inject) => {
  const app = !firebase.apps.length
    ? firebase.initializeApp({
        projectId: context.$config.FIREBASE_PROJECT_ID,
        storageBucket: context.$config.FIREBASE_STORAGE_BUCKET,
      })
    : firebase.app();
  const firebaseDb = app.firestore();
  inject('firebaseDb', firebaseDb);
  context.$firebaseDb = firebaseDb;
  inject('firebase', app);
  context.$firebase = app;
};

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
// const { TimeStamp, GeoPoint } = firebase.firestore;
// export { TimeStamp, GeoPoint };
