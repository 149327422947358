<template>
  <div class="dn-l mobile-menu-container">
    <nav class="flex items-center justify-between mobile-menu">
      <nuxt-link
        v-if="$config.FT_HERO_SEARCH_MOBILE"
        :to="localePath({ name: 'index' })"
        class="mobile-menu-link mobile-menu-link--search"
      >
        <svg-icon name="icon-search" class="icon-search" />
        <span class="db">{{ $t('navigation.search') }}</span>
      </nuxt-link>
      <nuxt-link
        v-if="!$config.FT_HERO_SEARCH_MOBILE"
        :to="localePath({ name: 'services' })"
        class="mobile-menu-link mobile-menu-link--services"
      >
        <svg-icon name="icon-logo-small" class="icon-logo-small" />
        <span class="db">{{ $t('navigation.services') }}</span>
      </nuxt-link>
      <nuxt-link
        v-if="isAuthenticated && $config.FT_ORDERS"
        :to="localePath({ name: 'tasks' })"
        class="mobile-menu-link mobile-menu-link--tasks"
      >
        <svg-icon name="icon-tasks" class="icon-tasks" />
        <span class="db">{{ $t('navigation.tasks') }}</span>
      </nuxt-link>
      <nuxt-link
        v-if="false"
        :to="localePath({ name: 'messages' })"
        class="mobile-menu-link"
      >
        <svg-icon name="icon-chat" class="icon-chat" />
        <span class="db">{{ $t('navigation.messages') }}</span>
      </nuxt-link>
      <a
        class="mobile-menu-link"
        :href="`https://help.discontract.com/v/${helpLinkLang}`"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg-icon name="icon-help" class="icon-help" />
        <span class="db">{{ $t('navigation.help') }}</span>
      </a>
      <nuxt-link
        v-if="false"
        :to="localePath({ name: 'account' })"
        class="mobile-menu-link"
      >
        <svg-icon name="icon-profile" class="icon-profile" />
        <span class="db">{{ $t('navigation.account') }}</span>
      </nuxt-link>
      <a
        v-show="!isAuthenticated && $config.FT_ORDERS"
        class="mobile-menu-link"
        href="#"
        @click.prevent="handleLogin()"
      >
        <svg-icon name="icon-profile" class="icon-profile" />
        <span class="db">{{ $t('navigation.login') }}</span>
      </a>
      <a
        v-show="isAuthenticated && $config.FT_ORDERS"
        class="mobile-menu-link"
        href="#"
        @click.prevent="handleLogout"
      >
        <svg-icon name="icon-logout" class="icon-logout" />
        <span class="db">{{ $t('navigation.logout') }}</span>
      </a>
    </nav>
  </div>
</template>

<script>
import utils from '~/plugins/utilities';

export default {
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true,
    },
    currentLang: {
      type: String,
      required: true,
    },
    handleLogin: {
      type: Function,
      required: true,
    },
    handleLogout: {
      type: Function,
      required: true,
    },
  },
  computed: {
    helpLinkLang() {
      return utils.getHelpLinkLanguage({ currentLang: this.currentLang });
    },
  },
};
</script>
