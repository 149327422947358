export const state = () => ({
  chatTaskId: null,
});

export const mutations = {
  setChatTaskId(state, { chatTaskId }) {
    // console.log('SET CHAT TASK ID', chatTaskId);
    state.chatTaskId = chatTaskId;
  },
};

export const actions = {
  openChat({ commit }, { chatTaskId }) {
    commit('setChatTaskId', { chatTaskId });
  },
};
