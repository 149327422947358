export default function ({ redirect, store, $axios }) {
  // If the user is not authenticated
  if (!store.state.auth.isAuthenticated || !store.state.auth.isRegistered) {
    store.dispatch('auth/logout');
    redirect(`/`);
  } else if (store.state.auth && store.state.auth.token) {
    // if token exists, then set it in axios
    $axios.defaults.headers.common.Authorization = `Bearer ${store.state.auth.token}`;
    // $analytics.trackEvent(events.LOAD_AUTH);
  }
}
