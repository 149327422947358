export default (context, inject) => {
  const utilsMethods = {
    localize(ctx, o) {
      // i18n is not in context because lazy loaded
      // console.log('CONTEXt', context);
      const locale = ctx.$i18n.locale;
      // console.log('LOCALE', locale, o);
      const language = locale.split('-')[0];
      return o?.[language];
    },
    getLanguage() {
      const locale = context.i18n.locale;
      // console.log('LOCALE', locale, o);
      const language = locale.split('-')[0];
      return language;
    },
  };

  inject('loc', utilsMethods.localize);
  inject('lang', utilsMethods.getLanguage);
  // context.$analytics = analyticsMethods;
};
