<template>
  <footer id="footer" class="pt4 pb2 f6 footer">
    <div class="container pax20">
      <div class="flex-l f5 mb2">
        <ul class="list pa0 w-50-l">
          <li class="mb2">
            <nuxt-link
              :to="localePath({ name: 'index' })"
              tabindex="0"
              class="link dib pv3"
            >
              <img
                class="dib v-mid footer-logo"
                src="~/assets/images/logo-discontract-multi.svg"
                width="126"
                height="30"
                alt="Discontract logo"
              />
            </nuxt-link>
          </li>
          <li class="mt3 flex justify-center-m">
            <a
              :href="iosLink"
              class="link mr2"
              tabindex="0"
              target="_blank"
              rel="noopener noreferrer"
              @click.stop="
                $analytics.trackEvent(events.EXECUTE_GET_APP, {
                  from: $route.path,
                  medium: 'app_store_link',
                })
              "
            >
              <img
                class="lazyload"
                data-src="~assets/images/badge-app-store.svg"
              />
            </a>
            <a
              :href="androidLink"
              class="link"
              tabindex="0"
              target="_blank"
              rel="noopener noreferrer"
              @click.stop="
                $analytics.trackEvent(events.EXECUTE_GET_APP, {
                  from: $route.path,
                  medium: 'google_play_link',
                })
              "
            >
              <img
                class="lazyload"
                data-src="~assets/images/badge-google-play.svg"
              />
            </a>
          </li>
          <li class="mt2">
            <a
              class="mv1 pv1 link inline-flex items-center footer-link"
              tabindex="0"
              :href="`https://help.discontract.com/v/${helpLinkLang}`"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg-icon name="icon-help" class="mr2 icon-help" />
              <span>{{ $t('navigation.footerHelp') }}</span>
            </a>
          </li>
          <li>
            <a
              class="mv1 pv1 link inline-flex items-center footer-link"
              href="#"
              tabindex="0"
              @click.prevent="
                $store.dispatch('modal/toggle', { modal: 'languageModal' })
              "
            >
              <svg-icon name="icon-globe" class="mr2 icon-globe" />
              <span>{{ $t(`languages.${currentLang}.long`) }}</span>
            </a>
          </li>
          <li>
            <a
              class="mv1 pv1 link inline-flex items-center footer-link"
              href="#"
              tabindex="0"
              @click.prevent="
                $store.dispatch('modal/toggle', { modal: 'countryModal' })
              "
            >
              <svg-icon name="icon-pin" class="mr2 icon-pin" />
              <span>{{ $t(`countries.${currentCountry}`) }}</span>
            </a>
          </li>
        </ul>
        <ul class="list pa0 w-20-l pl0 pl4-l">
          <li class="pv1 ph0 ph2-l dib fs-title mb1 mt3 fw7">
            {{ $t('navigation.platform') }}
          </li>
          <li>
            <nuxt-link
              :to="localePath({ name: 'services' })"
              tabindex="0"
              class="link pv1 ph0 ph2-l mv1 dib"
            >
              {{ $t('navigation.services') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="localePath({ name: 'business' })"
              tabindex="0"
              class="link pv1 ph0 ph2-l mv1 dib"
            >
              {{ $t('navigation.business') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="localePath({ name: 'prices' })"
              tabindex="0"
              class="link pv1 ph0 ph2-l mv1 dib"
            >
              {{ $t('navigation.costs') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="localePath({ name: 'pro-signup' })"
              tabindex="0"
              class="link pv1 ph0 ph2-l mv1 dib"
            >
              {{ $t('navigation.proSignup') }}
            </nuxt-link>
          </li>
        </ul>
        <ul class="list pa0 w-20-l pl0 pl3-l">
          <li class="pv1 ph0 ph2-l dib fs-title fw7 mb1 mt3">
            {{ $t('navigation.company') }}
          </li>
          <li>
            <nuxt-link
              :to="localePath({ name: 'about' })"
              class="link pv1 ph0 ph2-l dib mv1"
            >
              {{ $t('navigation.about') }}
            </nuxt-link>
          </li>
          <!-- <li>
            <nuxt-link
              :to="localePath({ name: 'careers' })"
              tabindex="0"
              class="link pv1 ph0 ph2-l dib mv1"
            >
              {{ $t('navigation.careers') }}
            </nuxt-link>
          </li> -->
          <li>
            <nuxt-link
              :to="localePath({ name: 'blog' })"
              tabindex="0"
              class="link pv1 ph0 ph2-l dib mv1"
            >
              {{ $t('navigation.blog') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="localePath({ name: 'press' })"
              tabindex="0"
              class="link pv1 ph0 ph2-l dib mv1"
            >
              {{ $t('navigation.press') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="localePath({ name: 'contact' })"
              tabindex="0"
              class="link pv1 ph0 ph2-l dib mv1"
            >
              {{ $t('navigation.contact') }}
            </nuxt-link>
          </li>
        </ul>
        <ul class="list pa0 w-20-l pl1">
          <li class="pv1 ph0 ph2-l dib fs-title fw7 mb1 mt3">
            {{ $t('navigation.follow') }}
          </li>
          <li v-if="$i18n.locale.split('-')[1] === 'PL'">
            <a
              href="https://www.facebook.com/DiscontractPolska/"
              class="link pv1 ph0 ph2-l dib mv1"
              tabindex="0"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li>
          <li v-else>
            <a
              href="https://www.facebook.com/discontract/"
              class="link pv1 ph0 ph2-l dib mv1"
              tabindex="0"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/discontract"
              class="link pv1 ph0 ph2-l dib mv1"
              tabindex="0"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </li>
        </ul>
      </div>
      <div class="mt2 mt5-l pt4 f5 flex-l flex-row-reverse-l justify-between-l">
        <ul class="list pa0 mv0 flex-l">
          <li>
            <a
              href="https://discontract.com/terms"
              class="link mr4 mv1 dib"
              tabindex="0"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t('navigation.terms') }}
            </a>
          </li>
          <li>
            <a
              class="link mv1 dib"
              href="https://discontract.com/privacy"
              tabindex="0"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t('navigation.privacy') }}
            </a>
          </li>
        </ul>
        <p class="pv2 mv1 dib">{{ $t('navigation.copyright') }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
import utils from '~/plugins/utilities';
import events from '~/constants/events';

export default {
  props: {
    iosLink: {
      type: String,
      required: true,
    },
    androidLink: {
      type: String,
      required: true,
    },
    currentLang: {
      type: String,
      required: true,
    },
    currentCountry: {
      type: String,
      required: true,
    },
  },
  computed: {
    helpLinkLang() {
      return utils.getHelpLinkLanguage({ currentLang: this.currentLang });
    },
    events() {
      return events;
    },
  },
};
</script>
