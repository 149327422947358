<template>
  <div class="country-dropdown">
    <div class="relative tl">
      <a
        v-click-outside="hide"
        :value="selected"
        :class="{ active: open }"
        class="tc dib pr3 pl2 bw1 link bg-white pointer dropdown-value hover-black"
        @click="toggle"
      >
        +{{ selected }}
      </a>
      <div
        :class="{ hidden: !open }"
        class="dropdown z-999 overflow-hidden absolute o-100 h6 top-3 left-0 shadow-2 br3 bg-white mt3 nl3"
      >
        <div class="flex items-center mt2 pv2 ph3">
          <label
            class="width-15 tc inline-flex justify-center country-search-label"
            for="search"
          >
            <svg-icon name="icon-search" class="icon-search" />
          </label>
          <input
            ref="search"
            v-model="keyword"
            name="search"
            type="text"
            class="search-input input-reset outline-0 db pv1 ph2"
            @input="filterList"
          />
        </div>
        <ul class="scroll-list overflow-y-scroll ma0 pa0 list">
          <li
            v-for="(option, index) in filteredOptions"
            :key="index"
            :value="option.value"
            :class="[option.class, { current: isCurrent(option.value) }]"
            class="flex justify-between items-center pv2 ph3 hover-bg-light-gray pointer"
            @click="select"
          >
            <!-- <span
              :class="`width-15 dib flag-icon flag-icon-${option.code.toLowerCase()}`"
            ></span> -->
            <div class="w-60 pv1 ph2 tl">{{ option.text }}</div>
            <div class="w-25 pv1 ph2 tr">+{{ option.value }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  directives: {
    ClickOutside,
  },
  props: {
    default: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => {
        return [
          {
            value: '',
            text: '',
            code: '',
          },
        ];
      },
    },
    defaultClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
      selected: this.default,
      selectedClass: this.defaultClass,
      keyword: '',
      filteredOptions: this.options,
    };
  },
  mounted() {
    this.popupItem = this.$el;
  },
  methods: {
    toggle() {
      this.open = !this.open;
      this.$refs.search.focus();
    },
    hide() {
      this.open = false;
    },
    select(e) {
      const code = e.currentTarget.getAttribute('value');
      this.selected = code;
      this.selectedClass = e.currentTarget.getAttribute('class');
      this.$emit('code', code);
      this.toggle();
    },
    isCurrent(value) {
      return this.selected === value;
    },
    filterList() {
      const filtered = this.options.filter((item) =>
        this.keyword === ''
          ? item.text.toLowerCase()
          : item.text.toLowerCase().includes(this.keyword.toLowerCase())
      );
      const unique = [];
      const map = new Map();
      for (const item of filtered) {
        if (!map.has(item.code)) {
          map.set(item.code, true); // set any value to Map
          unique.push(item);
        }
      }
      this.filteredOptions = unique;
    },
  },
};
</script>

<style scoped>
.dropdown {
  width: 350px;
}
.scroll-list {
  max-height: 300px;
}
.hidden {
  margin-top: 0;
  opacity: 0;
  height: 0;
}
.width-15 {
  width: 15%;
}
.search-input {
  border: none;
  width: 85%;
}
.dropdown-value {
  width: 60px;
}
</style>
