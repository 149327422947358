<template>
  <fixed-header
    v-if="isHeaderVisible()"
    header-class="header--fixed"
    fixed-class="header--is-scrolled"
  >
    <header
      class="header"
      :class="{
        'header--search':
          isHeaderSearchLargeEnabled() && isHeaderSearchLargeDisplayed(),
      }"
    >
      <div class="flex-l h-100 container header-container">
        <div class="flex justify-between items-center h-100 w-100">
          <nuxt-link
            :to="localePath({ name: 'index' })"
            class="flex items-center home-link"
            tabindex="0"
            @click.native="$store.dispatch('modal/closeAll')"
          >
            <img
              class="header-logo header-logo--white"
              :src="require(`~/assets/images/logo-discontract-white.svg`)"
              width="126"
              height="30"
              alt="Discontract"
            />
            <img
              class="header-logo header-logo--multi"
              :src="require(`~/assets/images/logo-discontract-multi.svg`)"
              width="126"
              height="30"
              alt="Discontract"
            />
          </nuxt-link>
          <client-only
            v-if="
              isHeaderSearchLargeEnabled() && isHeaderSearchLargeDisplayed()
            "
          >
            <SearchLarge v-if="$screen.large" />
          </client-only>
          <div class="relative flex justify-center items-center">
            <div v-show="$config.FT_ORDERS" class="dn db-l">
              <nuxt-link
                :to="localePath({ name: 'pro-signup' })"
                class="link header-link header-link--pro"
                tabindex="0"
                @click.native="$store.dispatch('modal/closeAll')"
              >
                {{ $t('navigation.proSignup') }}
              </nuxt-link>
            </div>
            <a
              class="link header-link header-link--language"
              href="#"
              tabindex="0"
              @click.prevent="
                $store.dispatch('modal/toggle', { modal: 'languageModal' })
              "
            >
              <span class="flex items-center">
                <svg-icon name="icon-globe" class="icon-globe" />
                <span>{{ $t(`languages.${currentLang}.short`) }}</span>
              </span>
            </a>
            <div v-click-outside="closeMenuDropdown">
              <button
                class="button-reset dn flex-l items-center br-pill header-menu-toggle"
                tabindex="0"
                @click.prevent="toggleMenuDropdown"
              >
                <span
                  class="flex items-center justify-center br-100 icon-container"
                >
                  <svg-icon name="icon-profile" class="icon-profile" />
                </span>
                <span class="db">
                  <svg-icon
                    name="icon-arrow-down"
                    class="icon-arrow-down"
                    :class="{ 'is-menu-open': isMenuOpen }"
                  />
                </span>
              </button>
              <div
                v-show="isMenuOpen"
                class="absolute w-100 header-menu-dropdown"
              >
                <ul class="list header-menu" @click="closeMenuDropdown">
                  <li v-show="!$config.FT_ORDERS" class="db w-100">
                    <a
                      class="link header-menu-link"
                      tabindex="0"
                      href="#"
                      @click.prevent="handleGetTheApp"
                    >
                      {{ $t('home.getTheApp') }}
                    </a>
                  </li>
                  <li
                    v-show="!isAuthenticated && $config.FT_ORDERS"
                    class="db w-100"
                  >
                    <a
                      class="link header-menu-link"
                      tabindex="0"
                      href="#"
                      @click.prevent="handleLogin()"
                    >
                      {{ $t('navigation.signup') }}
                    </a>
                  </li>
                  <li
                    v-show="!isAuthenticated && $config.FT_ORDERS"
                    class="db w-100"
                  >
                    <a
                      class="link header-menu-link"
                      tabindex="0"
                      href="#"
                      @click.prevent="handleLogin()"
                    >
                      {{ $t('navigation.login') }}
                    </a>
                  </li>
                  <li class="db w-100">
                    <a
                      class="link header-menu-link"
                      tabindex="0"
                      :href="`https://help.discontract.com/v/${helpLinkLang}`"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ $t('navigation.help') }}
                    </a>
                  </li>
                  <li v-if="false" class="db w-100">
                    <hr class="header-menu-separator" />
                  </li>
                  <li
                    v-if="isAuthenticated && $config.FT_ORDERS"
                    class="db w-100"
                  >
                    <nuxt-link
                      :to="localePath({ name: 'tasks' })"
                      class="link header-menu-link"
                      tabindex="0"
                    >
                      {{ $t('navigation.tasks') }}
                    </nuxt-link>
                  </li>
                  <li
                    v-if="isAuthenticated && $config.FT_ORDERS"
                    class="db w-100"
                  >
                    <a
                      class="link header-menu-link"
                      tabindex="0"
                      href="#"
                      @click.prevent="handleLogout"
                    >
                      {{ $t('navigation.logout') }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </fixed-header>
</template>

<script>
import FixedHeader from 'vue-fixed-header';
import ClickOutside from 'vue-click-outside';
import SearchLarge from '~/components/Search/SearchLarge';
import utils from '~/plugins/utilities';

export default {
  components: {
    FixedHeader,
    SearchLarge,
  },
  directives: {
    ClickOutside,
  },
  props: {
    hasHeaderSearch: {
      type: Boolean,
      required: true,
    },
    isAuthenticated: {
      type: Boolean,
      required: true,
    },
    currentLang: {
      type: String,
      required: true,
    },
    handleLogin: {
      type: Function,
      required: true,
    },
    handleLogout: {
      type: Function,
      required: true,
    },
    handleGetTheApp: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  computed: {
    helpLinkLang() {
      return utils.getHelpLinkLanguage({ currentLang: this.currentLang });
    },
  },
  methods: {
    toggleMenuDropdown() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenuDropdown() {
      this.isMenuOpen = false;
    },
    getCountryCode() {
      return this.$i18n.locale.split('-')[1];
    },
    isHeaderVisible() {
      const currentRoute = utils.getCurrentRoute({ route: this.$route });
      return currentRoute !== 'cart';
    },
    isHeaderSearchLargeEnabled() {
      return (
        this.$config.FT_HEADER_SEARCH_LARGE &&
        (this.getCountryCode() === 'LT' ||
          (this.$config.FT_PL_ORDERS && this.getCountryCode() === 'PL'))
      );
    },
    isHeaderSearchLargeDisplayed() {
      const currentRoute = utils.getCurrentRoute({ route: this.$route });

      return (
        (currentRoute === 'index' && this.hasHeaderSearch) ||
        currentRoute === 'skill' ||
        currentRoute === 'services' ||
        currentRoute === 'pro'
      );
    },
  },
};
</script>
