<template>
  <div
    class="mobile-search-header"
    :class="{
      'is-visible': isSearchMobileHeaderVisible(),
    }"
  >
    <div class="flex-l h-100 container mobile-search-header-container">
      <div class="flex justify-between items-center h-100 w-100">
        <SearchMobile />
      </div>
    </div>
  </div>
</template>

<script>
import searchMixin from '~/components/Search/searchMixin';
import SearchMobile from '~/components/Search/SearchMobile';
import utils from '~/plugins/utilities';

export default {
  components: {
    SearchMobile,
  },
  mixins: [searchMixin],
  props: {
    hasHeaderSearch: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    isSearchMobileHeaderVisible() {
      const currentRoute = utils.getCurrentRoute({ route: this.$route });

      return (
        (currentRoute === 'index' && this.hasHeaderSearch) ||
        currentRoute === 'services'
      );
    },
  },
};
</script>
