/* eslint-disable */
import { v4 as uuidv4 } from 'uuid';
import events from '~/constants/events';
import api from '../services/api';

export default (context, inject) => {
  // get consent value from Cookies
  let { consent } = context.$cookies.get('cookie-preferences') || {};

  // get or set anonymous Id
  let { anonymousId } = context.$cookies.get('app') || {};
  if (!anonymousId) {
    anonymousId = uuidv4();
    context.app.$cookies.set(
      'app',
      { anonymousId },
      {
        path: '/',
        maxAge: 60 * 60 * 24 * 7 * 4,
      }
    );
  }
  // init Google Analytics
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  if (consent) {
    gtag('consent', 'default', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
  } else {
    gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
    });
  }
  gtag('js', new Date());
  gtag('config', context.$config.GOOGLE_ANALYTICS_UNIVERSAL);
  gtag('config', context.$config.GOOGLE_ANALYTICS_APP_WEB);

  // init Facebook
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  );
  if (!consent) {
    fbq('consent', 'revoke');
  }
  fbq('init', context.$config.FACEBOOK_PIXEL);
  fbq('track', 'PageView');

  // additional methods to track events in components
  const analyticsMethods = {
    async trackEvent(name, payload) {
      // console.log('BI LOG', name, payload);
      try {
        await api.trackEvents(context, {
          anonymousId,
          consent,
          events: [
            {
              event: name,
              params: payload,
              timestamp: Date.now(),
            },
          ],
        });

        await gtag('event', name, payload);
        await fbq('trackCustom', name, payload);
      } catch (e) {
        console.error(e);
      }
    },
    trackPurchase(value, skillId, proId, currency) {
      // TODO: Send to BI

      // Google Analytics Purchase
      gtag('event', 'purchase', {
        transaction_id: uuidv4(),
        value: value / 100,
        currency,
        items: [
          {
            item_id: proId,
            item_name: proId,
            item_category: skillId,
          },
        ],
      });
      // Facebook Purchase
      fbq('track', 'Purchase', {
        value: value / 100,
        currency: 'EUR',
        content_category: skillId,
        content_ids: [proId],
        content_name: proId,
        content_type: 'product',
      });
    },
    trackAttribution(utmParams) {
      console.log('TRACK ATTRIBUTION', utmParams);
      // bi.trackAttribution(source, medium, campaign);
    },
    updateConsent(consentVal) {
      // update value for further events
      consent = consentVal;
      if (consentVal) {
        gtag('consent', 'update', {
          ad_storage: 'granted',
          analytics_storage: 'granted',
        });
        fbq('consent', 'grant');
      } else {
        gtag('consent', 'update', {
          ad_storage: 'denied',
          analytics_storage: 'denied',
        });
        fbq('consent', 'revoke');
      }
    },
  };

  // track page views
  context.app.router.afterEach((to, from) => {
    analyticsMethods.trackEvent(events.VIEW_PAGE, {
      path: context.route.path,
    });
    if (
      to.query &&
      to.query.utm_campaign &&
      to.query.utm_medium &&
      to.query.utm_source
    ) {
      analyticsMethods.trackEvent(events.ATTRIBUTE_VISIT, {
        path: context.route.path,
        campaign: to.query.utm_campaign,
        medium: to.query.utm_medium,
        source: to.query.utm_source,
      });
    }
  });

  inject('analytics', analyticsMethods);
  context.$analytics = analyticsMethods;
};
