<template>
  <div
    v-show="showConsent"
    class="fixed bottom-0 right-0 w-100 z-max cookies-banner-container"
  >
    <div class="flex flex-column flex-row-l items-center br2 cookies-banner">
      <div class="w-60-l w-65-xl">
        <p class="pr2-l">
          {{ $t('common.cookies.message') }}
          <a
            class="ml1-l"
            href="https://discontract.com/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $t('common.cookies.link') }}
          </a>
        </p>
      </div>
      <div
        class="w-100 w-40-l w-35-xl flex flex-column items-center justify-center-ns inline-flex-ns flex-row-ns justify-end-l"
      >
        <a
          class="dib fw7 br-pill tc pv2 ph3 cookies-banner-button"
          href="#"
          @click.prevent="updateConsent(false)"
        >
          {{ $t('common.cookies.rejectAll') }}
        </a>
        <a
          class="dib fw7 br-pill tc pv2 ph3 cookies-banner-button"
          style="background-color: #20d0ae; color: white; border: 0"
          href="#"
          @click.prevent="updateConsent(true)"
        >
          {{ $t('common.cookies.acceptAll') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showConsent: {
      type: undefined,
      required: true,
    },
    updateConsent: {
      type: Function,
      required: true,
    },
  },
};
</script>
