<template>
  <div class="relative">
    <div v-if="!countryOpen" class="flex-l items-center nl3 nr3 mt3 mb4">
      <a
        class="db link pointer w-100 w-25-l pl3 flex items-center"
        href="#"
        tabindex="0"
        @click.prevent="toggleCountries"
      >
        <span
          :class="`dib flag-icon flag-icon-${currentCountry.toLowerCase()} br-pill ml1 mr2`"
          style="flex-shrink: 0"
        ></span>
        <span class="dib current-country pr3">{{
          $t(`countries.${currentCountry}`)
        }}</span>
        <span class="dib pl3">{{ $t('common.showAllCountries') }}</span>
      </a>
      <ul class="w-100 w-75-l list ma0 pa0 mt4 mt0-l flex-l flex-wrap">
        <li
          v-for="city in locations[currentCountry]"
          :key="city"
          class="w-100 w-third-l mv2 ph3"
        >
          <nuxt-link
            :to="
              localePath(
                {
                  name: 'services-city',
                  params: {
                    city: getSlug($t(`cities.${currentCountry}.${city}`)),
                  },
                },
                getLocaleForCountry(currentCountry)
              )
            "
            :class="
              $route.params.city ===
              getSlug($t(`cities.${currentCountry}.${city}`))
                ? 'active'
                : ''
            "
            class="db pointer link pa3 br-pill styled-input flex items-center"
            @click.native="setLocale()"
          >
            <span class="flex-auto">{{
              $t('cities.' + currentCountry + '.' + city)
            }}</span>
            <span class="tr mr1">
              <img
                src="~/assets/icons/ic_arrow_right.svg"
                class="content-end"
                width="8"
                height="13"
              />
            </span>
          </nuxt-link>
        </li>
      </ul>
    </div>
    <ul
      v-if="countryOpen"
      class="list ma0 pa0 mt4 mt0-l flex-l flex-wrap nr3 nl3 mt3 mb4"
    >
      <li
        v-for="(cities, country) in locations"
        :key="country"
        class="w-100 w-25-l mv2 ph3"
      >
        <a
          class="pointer db link pa3 br-pill styled-input flex items-center"
          @click="setCountry(country)"
        >
          <span
            :class="`dib flag-icon flag-icon-${country.toLowerCase()} br-pill ml1 mr2`"
          ></span>
          <span class="flex-auto"
            >{{ $t(`countries.${country}`) }} ({{ cities.length }})</span
          >
          <span class="tr mr1">
            <img
              src="~/assets/icons/ic_arrow_right.svg"
              class="content-end"
              width="8"
              height="13"
            />
          </span>
        </a>
      </li>
    </ul>
    <div
      v-if="countryOpen && !modalView"
      class="absolute right-0"
      style="top: -60px"
    >
      <a
        class="dib pointer pa3 br-pill styled-input city-chooser-close"
        href="#"
        tabindex="0"
        @click.prevent="toggleCountries"
      >
        <svg-icon name="icon-close-small" class="icon-close-small" />
      </a>
    </div>
  </div>
</template>

<script>
import slug from 'slug';
import utils from '~/plugins/utilities';

export default {
  props: {
    showCountries: {
      type: Boolean,
      default: false,
    },
    modalView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countryOpen: this.showCountries,
      locations: {
        LT: [
          'vilnius',
          'kaunas',
          'klaipeda',
          'marijampole',
          'mazeikiai',
          'panevezys',
          'siauliai',
          'ukmrege',
          'utena',
        ],
        // LV: ['riga'],
        // EE: ['tallinn'],
        PL: ['warsaw'],
        // UA: ['kiev', 'lviv'],
      },
      currentCountry: this.$i18n.locale.split('-')[1],
    };
  },
  computed: {
    currentLang() {
      return this.$i18n.locale.split('-')[0];
    },
  },
  methods: {
    getLocaleForCountry(country) {
      // check if next user locale is in array
      const nextLocale = this.$i18n.locale.split('-')[0] + '-' + country;
      if (this.$i18n.locales.map((l) => l.code).includes(nextLocale)) {
        return nextLocale;
      }
      return 'en-' + country;
    },
    setCountry(country) {
      this.currentCountry = country;
      this.toggleCountries();
    },
    toggleCountries() {
      this.countryOpen = !this.countryOpen;
    },
    getSlug(text) {
      return slug(text, { locale: this.currentLang });
    },
    setLocale() {
      if (this.modalView) {
        this.$store.dispatch('modal/toggle', { modal: 'countryModal' });
      }
      utils.setLocale(this, this.getLocaleForCountry(this.currentCountry));
    },
  },
};
</script>

<style scoped>
.flag-icon {
  background-size: cover;
  width: 20px;
  height: 20px;
  border: solid 1px #dedddd;
}

.current-country {
  border-right: solid 1px #dedddd;
}
</style>
