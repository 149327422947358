<template>
  <div>
    <div
      style="min-width: 100px"
      class="br-pill flex pointer pa2 justify-between white items-center"
      :class="value ? 'bg-purple ' : 'bg-light-silver'"
      @click="toggle()"
    >
      <div v-if="value" class="mr2">{{ okLabel }}</div>
      <div class="bg-white br-pill" style="width: 34px; height: 34px"></div>
      <div v-if="!value" class="mr2">{{ noLabel }}</div>
    </div>
  </div>
</template>

<script>
// TODO: in order to be accessible this toggle should hide checkbox under it
export default {
  props: {
    okLabel: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: Boolean,
      default: false,
    },
    noLabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: this.defaultValue || false,
    };
  },
  methods: {
    toggle() {
      this.value = !this.value;
      this.$emit('input', this.value);
    },
  },
};
</script>
