export const state = () => ({
  isAuthenticated: false,
  isRegistered: false,
  token: null,
  preauth: null,
});

export const mutations = {
  save(state, { token }) {
    state.token = token;
    state.isAuthenticated = true;
  },
  remove(state) {
    state.token = null;
    state.isAuthenticated = false;
    state.preauth = null;
    state.isRegistered = false;
  },
  verify(state, { preauth }) {
    state.preauth = preauth;
  },
  register(state) {
    state.isRegistered = true;
  },
};

export const actions = {
  login({ commit }, { token }) {
    commit('save', { token });
  },
  register({ commit }) {
    commit('register');
  },
  verify({ commit }, { preauth }) {
    commit('verify', { preauth });
  },
  logout({ commit }) {
    commit('remove');
  },
};
