<template>
  <div class="country-codes">
    <div class="relative tl">
      <div class="">
        <div class="flex items-center mt2 pv2 ph3">
          <label
            class="width-15 tc inline-flex justify-center country-search-label"
            for="search"
          >
            <svg-icon name="icon-search" class="icon-search" />
          </label>
          <input
            ref="search"
            v-model="keyword"
            name="search"
            type="text"
            class="search-input input-reset outline-0 db pv1 ph2"
            autofocus
            @input="filterList"
          />
        </div>
        <ul class="scroll-list ma0 pa0 list">
          <li
            v-for="(option, index) in filteredOptions"
            :key="index"
            :value="option.value"
            :class="[option.class, { current: isCurrent(option.value) }]"
            class="flex justify-between items-center pv2 ph3 hover-bg-light-gray pointer"
            @click="select"
          >
            <span
              :class="`width-15 dib flag-icon flag-icon-${option.code.toLowerCase()}`"
            ></span>
            <div class="w-60 pv1 ph2 tl">{{ option.text }}</div>
            <div class="w-25 pv1 ph2 tr">+{{ option.value }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { PHONE_CODES } from '~/constants/phoneCodes';

export default {
  props: {
    default: {
      type: String,
      default: '',
    },
    defaultClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
      selected: this.default,
      selectedClass: this.defaultClass,
      keyword: '',
      filteredOptions: this.options,
    };
  },
  computed: {
    options() {
      return PHONE_CODES.map((item) => {
        return {
          code: item.iso2,
          value: item.dialCode,
          text: item.name,
        };
      });
    },
  },
  mounted() {
    this.popupItem = this.$el;
    this.$refs.search.focus();
  },
  created() {
    this.filterList();
  },
  methods: {
    toggle() {
      this.open = !this.open;
      this.$refs.search.focus();
    },
    hide() {
      this.open = false;
    },
    select(e) {
      const code = e.currentTarget.getAttribute('value');
      this.selected = code;
      this.selectedClass = e.currentTarget.getAttribute('class');
      this.$root.$emit('pop-dialog', code);
      this.toggle();
    },
    isCurrent(value) {
      return this.selected === value;
    },
    filterList() {
      const filtered = this.options.filter((item) =>
        this.keyword === ''
          ? item.text.toLowerCase()
          : item.text.toLowerCase().includes(this.keyword.toLowerCase())
      );
      const unique = [];
      const map = new Map();
      for (const item of filtered) {
        if (!map.has(item.code)) {
          map.set(item.code, true); // set any value to Map
          unique.push(item);
        }
      }
      this.filteredOptions = unique;
    },
  },
};
</script>

<style scoped>
.hidden {
  margin-top: 0;
  opacity: 0;
  height: 0;
}
.width-15 {
  width: 15%;
}
.search-input {
  border: none;
  width: 85%;
}
.dropdown-value {
  width: 60px;
}
</style>
