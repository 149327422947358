<template>
  <div class="container pax20">
    <div class="flex-l items-center pv5">
      <div class="w-50-l pr4">
        <h1 v-if="error.statusCode === 404">{{ $t('common.pageNotFound') }}</h1>
        <h1 v-else>{{ $t('common.errorOccurred') }}</h1>
        <nuxt-link
          :to="localePath({ name: 'index' })"
          class="pointer dib white fw7 br-pill bg-purple button-large no-underline"
          >{{ $t('common.home') }}</nuxt-link
        >
      </div>
      <div class="w-50-l">
        <img
          class="lazyload"
          data-src="~assets/images/not-found-illustration.svg"
          width="542"
          height="500"
          alt="Not found illustration"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500;
    },
    message() {
      return this.error.message;
    },
  },
  head() {
    return {
      title: this.message,
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0',
        },
      ],
      htmlAttrs: {
        class: 'html--error',
      },
    };
  },
};
</script>
