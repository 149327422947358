<template>
  <a
    type="submit"
    class="dib white fw7 br-pill bg-purple submit-button w-100 tc"
    :class="disabled ? 'bg-light-silver' : 'pointer'"
    @click="pressButton()"
  >
    <slot v-if="!loading"></slot>
    <div v-if="loading" class="loader" style="margin: 0 auto"></div>
  </a>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    pressButton() {
      if (!this.disabled && !this.loading) {
        this.$emit('press');
      }
    },
  },
};
</script>

<style scoped>
.submit-button {
  padding: 12px 30px;
  min-height: 52px;
}
.loader {
  border: 3px solid white; /* Light grey */
  border-top: 3px solid #b082f9; /* Blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
