<template>
  <div v-if="dialogComponent">
    <div v-scroll-lock="isDialogOpen" class="dialog" @click.stop>
      <header class="flex justify-between items-center dialog-header">
        <a
          class="db dialog-back"
          :class="dialogStackLength ? 'visible' : 'hidden'"
          tabindex="0"
          href="#"
          @click.prevent="popDialog()"
        >
          <svg-icon name="icon-arrow-back" class="icon-arrow-back" />
        </a>
        <h4 class="dialog-title">
          {{ dialogTitle }}
        </h4>
        <a
          class="db dialog-close"
          tabindex="0"
          href="#"
          @click.prevent="closeDialog()"
        >
          <svg-icon name="icon-close-small" class="icon-close-small" />
        </a>
      </header>
      <div class="dialog-content">
        <component
          :is="dialogComponent"
          :params="dialogParams"
          :pop-value="dialogPopValue"
          @closeDialog="closeDialog()"
        />
      </div>
    </div>
    <div class="dialog-overlay" @click.stop="closeDialog()" />
  </div>
</template>

<script>
export default {
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    dialogComponent: {
      type: [Object, Boolean],
      default: undefined,
    },
    dialogStackLength: {
      type: Number,
      default: 0,
    },
    dialogPopValue: {
      type: [Number, String],
      default: undefined,
    },
    dialogParams: {
      type: Object,
      default: undefined,
    },
    dialogTitle: {
      type: String,
      default: '',
    },
    popDialog: {
      type: Function,
      required: true,
    },
    closeDialog: {
      type: Function,
      required: true,
    },
  },
};
</script>
