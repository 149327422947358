const API_BASE = '/wapi/v1';

class ApiError extends Error {
  constructor({ method, url, status, cause, data }) {
    super(
      `Failed to query API ${method} ${url}${status ? ` (${status})` : ''}`
    );
    this.name = 'ApiError';
    this.cause = cause;
    this.message = data?.message;
  }
}

function getHeaders(context) {
  const store = context.$store || context.store;
  const token = store.state.auth?.token;
  if (token) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
}

async function performRequest(
  context,
  { path, method = 'get', params = {}, rootCall }
) {
  const url = (rootCall ? '' : API_BASE) + path;
  try {
    const response = await context.$axios({
      method,
      headers: getHeaders(context),
      url,
      data: params,
      params: method === 'get' ? params : {},
    });
    return response.data;
  } catch (e) {
    throw new ApiError({
      method,
      url,
      status: e.response?.status,
      cause: e,
      data: e.response?.data,
    });
  }
}

export default {
  saveCard: async (context) => {
    return await performRequest(context, {
      method: 'post',
      path: '/payment-card/generate-secret',
    });
  },

  confirmCard: async (context, params) => {
    return await performRequest(context, {
      method: 'post',
      path: '/payment-card',
      params,
    });
  },

  confirmOrderPayment: async (context, orderId) => {
    return await performRequest(context, {
      method: 'post',
      path: `/order/${orderId}/payment`,
    });
  },

  getPaymentMethods: async (context) => {
    const resp = await performRequest(context, {
      path: '/payment-cards',
    });
    return resp.paymentCards.sort((c1, c2) => {
      return c1.fingerprint > c2.fingerprint;
    });
  },

  getOrderSections: async (context, params) => {
    const sections = await performRequest(context, {
      path: `/order/sections`,
      params,
    });
    return sections;
  },

  getServices: async (context, params) => {
    const sections = await performRequest(context, {
      path: `/services`,
      params: { languages: ['lt', 'pl', 'en'], mode: 'c2c' },
    });
    return sections;
  },

  getSkill: async (context, skillId, languages) => {
    const sections = await performRequest(context, {
      path: `/skills/` + skillId,
      params: { languages },
    });
    return sections;
  },

  setDefaultPaymentMethod: async (context, params) => {
    await performRequest(context, {
      params,
      path: '/user/default-payment-method',
      method: 'put',
    });
    return 'OK';
  },

  setLanguage: async (context, params) => {
    await performRequest(context, {
      params,
      path: '/user/language',
      method: 'put',
    });
    return 'OK';
  },

  uploadImage: async (context, image) => {
    const formData = new FormData();
    formData.append('image', image);
    const res = await performRequest(context, {
      params: formData,
      path: `/image`,
      method: 'post',
    });
    return res;
  },

  smsVerify: async (context, params) => {
    const res = await performRequest(context, {
      params,
      path: '/login',
      method: 'post',
    });
    return res.preauthId;
  },

  validatePreauth: async (context, params) => {
    const res = await performRequest(context, {
      params,
      path: '/preauth/' + params.id + '/verify',
      method: 'post',
    });
    return res.authToken;
  },

  validateReferralCode: async (context, code) => {
    const res = await performRequest(context, {
      path: '/promos/' + code,
      method: 'get',
    });
    return res;
  },

  claimReferralCode: async (context, code, params) => {
    const res = await performRequest(context, {
      path: '/promos/' + code + '/claim',
      method: 'post',
      params,
    });
    return res;
  },

  downloadApp: async (context, params) => {
    const res = await performRequest(context, {
      path: '/app/download',
      method: 'post',
      params,
    });
    return res;
  },

  getCustomer: async (context) => {
    const res = await performRequest(context, {
      path: '/customer',
    });
    return res;
  },

  getOrder: async (context, orderId) => {
    return await performRequest(context, { path: `/orders/${orderId}` });
  },

  getOrderPaymentInfo: async (context, orderId) => {
    return await performRequest(context, {
      path: `/payment/${orderId}`,
    });
  },

  getOrderDetails: async (context, orderId, debug = '') => {
    return await performRequest(context, {
      path: `/order/${orderId}/details?debug=` + debug,
    });
  },

  setOrderSeen: async (context, orderId) => {
    return await performRequest(context, {
      method: 'PUT',
      path: `/order/${orderId}/seen`,
    });
  },

  getCart: async (context, cartId, itemId, cs) => {
    return await performRequest(context, {
      path: `/carts/${cartId}/${itemId}/dates?clientSecret=${cs}`,
    });
  },

  confirmCartOrder: async (context, cartId, itemId, cs, params) => {
    return await performRequest(context, {
      method: 'post',
      path: `/carts/${cartId}/${itemId}/confirm?clientSecret=${cs}`,
      params,
    });
  },

  getOrders: async (context) => {
    return await performRequest(context, { path: `/customer/orders` });
  },

  transferOrder: async (context, orderId, params) => {
    return await performRequest(context, {
      path: `/order/${orderId}`,
      method: 'put',
      params,
    });
  },

  getMessages: async (context, taskId) => {
    return await performRequest(context, { path: `/tasks/${taskId}/messages` });
  },

  getPro: async (context, proId) => {
    return await performRequest(context, { path: `/pros/${proId}` });
  },

  findPros: async (context, skillId, params) => {
    return await performRequest(context, {
      path: `/skills/${skillId}/pros`,
      params,
    });
  },

  registerCustomer: async (context, params) => {
    return await performRequest(context, {
      path: '/user',
      params,
      method: 'post',
    });
  },

  postMessage: async (context, taskId, data) => {
    return await performRequest(context, {
      method: 'post',
      path: `/tasks/${taskId}/message`,
      params: data,
    });
  },

  confirmOrder: async (context, orderId) => {
    return await performRequest(context, {
      method: 'post',
      path: `/order/${orderId}/task`,
    });
  },

  createOrder: async (context, params) => {
    return await performRequest(context, {
      method: 'post',
      path: `/order`,
      params,
    });
  },

  reviewTask: async (context, taskId, params) => {
    return await performRequest(context, {
      method: 'put',
      path: `/tasks/` + taskId + '/review',
      params,
    });
  },

  trackEvents: async (context, params) => {
    return await performRequest(context, {
      method: 'post',
      path: '/bi/events',
      params,
    });
  },
};
