<template>
  <Modal
    :is-open="modalOpen.open && modalOpen.target === 'downloadModal'"
    @toggle="$store.dispatch('modal/toggle', { modal: 'downloadModal' })"
    @open-download-modal="
      $store.dispatch('modal/open', { modal: 'downloadModal' })
    "
  >
    <p class="f3 ma0 pv4 tc">{{ $t('navigation.getTheApp') }}</p>
    <p class="tc">{{ $t('form.getTheApp.instruction') }}</p>
    <div class="flex justify-center">
      <GetAppForm />
    </div>
  </Modal>
</template>

<script>
import Modal from '~/components/Modal';
import GetAppForm from '~/components/GetAppForm';

export default {
  components: {
    Modal,
    GetAppForm,
  },
  props: {
    modalOpen: {
      type: Object,
      required: true,
    },
  },
};
</script>
