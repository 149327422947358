<template>
  <div class="mt4 mt0-l tl flex" style="flex-grow: 1">
    <form
      novalidate="true"
      class="flex flex-column"
      style="flex-grow: 1"
      @submit.prevent="submitForm"
    >
      <div class="flex flex-column h-100">
        <div class="pax20 h-100 justify-center items-center flex">
          <div style="max-width: 448px" class="w-100">
            <Input
              v-model="firstName"
              autofocus
              label="screen.register.name"
              position="top"
            />
            <Input
              v-model="lastName"
              label="screen.register.surname"
              position="middle"
            />
            <Input
              v-model="email"
              type="email"
              label="screen.register.email"
              position="bottom"
            />
            <div class="mt3 flex">
              <!-- eslint-disable vue/no-v-html -->
              <div
                class="mr2"
                v-html="$t('screen.register.acceptTerms.terms')"
              ></div>
              <!-- eslint-enable vue/no-v-html -->
              <div>
                <Toggle
                  v-model="acceptTerms"
                  :ok-label="$t('screen.register.acceptTerms.accept')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bt b--light-gray flex justify-center flex-column">
        <ErrorStripe v-if="error">
          {{ error }}
        </ErrorStripe>
        <div class="pax20 flex justify-center">
          <Button
            style="max-width: 448px"
            :loading="loading"
            :disabled="!validateForm()"
            @press="submitForm"
          >
            {{ $t('common.continue') }}
          </Button>
        </div>
      </div>
      <HoneyField value="honey" />
    </form>
  </div>
</template>

<script>
import HoneyField from '~/components/HoneyField';
import Input from '~/components/Input';
import Button from '~/components/Button';
import Toggle from '~/components/Toggle';
import Api from '~/services/api';
import ErrorStripe from '~/components/ErrorStripe';
import events from '~/constants/events';

function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export default {
  components: {
    HoneyField,
    Input,
    Button,
    ErrorStripe,
    Toggle,
  },
  data() {
    return {
      error: null,
      firstName: null,
      lastName: null,
      email: null,
      loading: false,
      acceptTerms: false,
      honey: '',
    };
  },
  destroyed() {
    if (!this.$store.state.auth.isRegistered) {
      this.$store.dispatch('auth/logout');
    }
  },
  methods: {
    async submitForm() {
      const data = {
        locale: this.$i18n.locale,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
      };
      this.loading = true;
      try {
        await Api.registerCustomer(this, {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
        });
      } catch (e) {
        this.loading = false;
        this.error = this.$t('common.errorOccurred');
        this.$sentry.captureException(e);
        return;
      }
      this.$analytics.trackEvent(events.FINISH_REGISTRATION);
      this.$store.dispatch('auth/register');
      this.$root.$emit('close-dialog');
    },
    validateForm() {
      if (this.honey !== '') {
        return false;
      }
      if (!this.firstName || !this.firstName.trim()) {
        // this.error = this.$t('form.requiredFirstName');
        return false;
      }
      if (!this.lastName || !this.lastName.trim()) {
        // this.error = this.$t('form.requiredLastName');
        return false;
      }
      if (!this.email || !validateEmail(this.email)) {
        // this.error = this.$t('form.requiredEmail');
        return false;
      }
      if (!this.acceptTerms) {
        return false;
      }
      // this.error = null;
      return true;
    },
  },
};
</script>
