import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _eed7e216 = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _7c634fea = () => interopDefault(import('../src/pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _3eaa8226 = () => interopDefault(import('../src/pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _4217c4b6 = () => interopDefault(import('../src/pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _bd9c6df0 = () => interopDefault(import('../src/pages/business.vue' /* webpackChunkName: "pages/business" */))
const _fb6f27c6 = () => interopDefault(import('../src/pages/careers.vue' /* webpackChunkName: "pages/careers" */))
const _41aeae40 = () => interopDefault(import('../src/pages/careers/index.vue' /* webpackChunkName: "pages/careers/index" */))
const _51573c60 = () => interopDefault(import('../src/pages/careers/marketing-project-manager.vue' /* webpackChunkName: "pages/careers/marketing-project-manager" */))
const _c66fc89e = () => interopDefault(import('../src/pages/careers/onboarding-specialist.vue' /* webpackChunkName: "pages/careers/onboarding-specialist" */))
const _6283d2c5 = () => interopDefault(import('../src/pages/careers/software-engineer-web-frontend.vue' /* webpackChunkName: "pages/careers/software-engineer-web-frontend" */))
const _936e0bf0 = () => interopDefault(import('../src/pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _af682c8c = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1214aaca = () => interopDefault(import('../src/pages/news.vue' /* webpackChunkName: "pages/news" */))
const _29c090de = () => interopDefault(import('../src/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _2809ef96 = () => interopDefault(import('../src/pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _7dd2ab94 = () => interopDefault(import('../src/pages/order.vue' /* webpackChunkName: "pages/order" */))
const _bb9976aa = () => interopDefault(import('../src/pages/press.vue' /* webpackChunkName: "pages/press" */))
const _3dc3cedc = () => interopDefault(import('../src/pages/prices.vue' /* webpackChunkName: "pages/prices" */))
const _31732cd6 = () => interopDefault(import('../src/pages/prices/index.vue' /* webpackChunkName: "pages/prices/index" */))
const _4e425d80 = () => interopDefault(import('../src/pages/pro-signup.vue' /* webpackChunkName: "pages/pro-signup" */))
const _831f19de = () => interopDefault(import('../src/pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _2e24a8d4 = () => interopDefault(import('../src/pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _5b502aa0 = () => interopDefault(import('../src/pages/profile/payment-methods/index.vue' /* webpackChunkName: "pages/profile/payment-methods/index" */))
const _5ef5d702 = () => interopDefault(import('../src/pages/profile/payment-methods/add.vue' /* webpackChunkName: "pages/profile/payment-methods/add" */))
const _45504e65 = () => interopDefault(import('../src/pages/referral.vue' /* webpackChunkName: "pages/referral" */))
const _4066cab4 = () => interopDefault(import('../src/pages/services.vue' /* webpackChunkName: "pages/services" */))
const _90efe6ae = () => interopDefault(import('../src/pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _35db63e4 = () => interopDefault(import('../src/pages/services/_city/index.vue' /* webpackChunkName: "pages/services/_city/index" */))
const _1c4d40d7 = () => interopDefault(import('../src/pages/services/_city/_service/index.vue' /* webpackChunkName: "pages/services/_city/_service/index" */))
const _487b52e0 = () => interopDefault(import('../src/pages/services/_city/_service/_skill/index.vue' /* webpackChunkName: "pages/services/_city/_service/_skill/index" */))
const _18d7fa60 = () => interopDefault(import('../src/pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _377d0314 = () => interopDefault(import('../src/pages/tasks.vue' /* webpackChunkName: "pages/tasks" */))
const _92d2570e = () => interopDefault(import('../src/pages/tasks/index.vue' /* webpackChunkName: "pages/tasks/index" */))
const _3e546308 = () => interopDefault(import('../src/pages/tasks/past-tasks.vue' /* webpackChunkName: "pages/tasks/past-tasks" */))
const _339438f3 = () => interopDefault(import('../src/pages/tasks/_orderId.vue' /* webpackChunkName: "pages/tasks/_orderId" */))
const _23b165d8 = () => interopDefault(import('../src/pages/o/_code.vue' /* webpackChunkName: "pages/o/_code" */))
const _2fe48a19 = () => interopDefault(import('../src/pages/pay/_orderId.vue' /* webpackChunkName: "pages/pay/_orderId" */))
const _b386fe1e = () => interopDefault(import('../src/pages/cart/_cartId/_itemId.vue' /* webpackChunkName: "pages/cart/_cartId/_itemId" */))
const _06bc4ff1 = () => interopDefault(import('../src/pages/pro/_proId/_name/index.vue' /* webpackChunkName: "pages/pro/_proId/_name/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _eed7e216,
    name: "about___en-LT"
  }, {
    path: "/blog",
    component: _7c634fea,
    children: [{
      path: "",
      component: _3eaa8226,
      name: "blog___en-LT"
    }, {
      path: "/blog/:slug",
      component: _4217c4b6,
      name: "blog-slug___en-LT"
    }]
  }, {
    path: "/business",
    component: _bd9c6df0,
    name: "business___en-LT"
  }, {
    path: "/careers",
    component: _fb6f27c6,
    children: [{
      path: "",
      component: _41aeae40,
      name: "careers___en-LT"
    }, {
      path: "marketing-project-manager",
      component: _51573c60,
      name: "careers-marketing-project-manager___en-LT"
    }, {
      path: "onboarding-specialist",
      component: _c66fc89e,
      name: "careers-onboarding-specialist___en-LT"
    }, {
      path: "software-engineer-web-frontend",
      component: _6283d2c5,
      name: "careers-software-engineer-web-frontend___en-LT"
    }]
  }, {
    path: "/contact",
    component: _936e0bf0,
    name: "contact___en-LT"
  }, {
    path: "/en-PL",
    component: _af682c8c,
    name: "index___en-PL"
  }, {
    path: "/lt-LT",
    component: _af682c8c,
    name: "index___lt-LT"
  }, {
    path: "/lt-PL",
    component: _af682c8c,
    name: "index___lt-PL"
  }, {
    path: "/news",
    component: _1214aaca,
    children: [{
      path: "",
      component: _29c090de,
      name: "news___en-LT"
    }, {
      path: "/news/:slug",
      component: _2809ef96,
      name: "news-slug___en-LT"
    }]
  }, {
    path: "/order",
    component: _7dd2ab94,
    name: "order___en-LT"
  }, {
    path: "/pl-LT",
    component: _af682c8c,
    name: "index___pl-LT"
  }, {
    path: "/pl-PL",
    component: _af682c8c,
    name: "index___pl-PL"
  }, {
    path: "/press",
    component: _bb9976aa,
    name: "press___en-LT"
  }, {
    path: "/prices",
    component: _3dc3cedc,
    children: [{
      path: "",
      component: _31732cd6,
      name: "prices___en-LT"
    }]
  }, {
    path: "/pro-signup",
    component: _4e425d80,
    name: "pro-signup___en-LT"
  }, {
    path: "/profile",
    component: _831f19de,
    children: [{
      path: "",
      component: _2e24a8d4,
      name: "profile___en-LT"
    }, {
      path: "payment-methods",
      component: _5b502aa0,
      name: "profile-payment-methods___en-LT"
    }, {
      path: "payment-methods/add",
      component: _5ef5d702,
      name: "profile-payment-methods-add___en-LT"
    }]
  }, {
    path: "/referral",
    component: _45504e65,
    name: "referral___en-LT"
  }, {
    path: "/services",
    component: _4066cab4,
    children: [{
      path: "",
      component: _90efe6ae,
      name: "services___en-LT"
    }, {
      path: ":city",
      component: _35db63e4,
      name: "services-city___en-LT"
    }, {
      path: ":city/:service",
      component: _1c4d40d7,
      name: "services-city-service___en-LT"
    }, {
      path: ":city/:service/:skill",
      component: _487b52e0,
      name: "services-city-service-skill___en-LT"
    }]
  }, {
    path: "/signup",
    component: _18d7fa60,
    name: "signup___en-LT"
  }, {
    path: "/tasks",
    component: _377d0314,
    children: [{
      path: "",
      component: _92d2570e,
      name: "tasks___en-LT"
    }, {
      path: "past-tasks",
      component: _3e546308,
      name: "tasks-past-tasks___en-LT"
    }, {
      path: ":orderId",
      component: _339438f3,
      name: "tasks-orderId___en-LT"
    }]
  }, {
    path: "/en-PL/about",
    component: _eed7e216,
    name: "about___en-PL"
  }, {
    path: "/en-PL/blog",
    component: _7c634fea,
    children: [{
      path: "",
      component: _3eaa8226,
      name: "blog___en-PL"
    }, {
      path: "/en-PL/blog/:slug",
      component: _4217c4b6,
      name: "blog-slug___en-PL"
    }]
  }, {
    path: "/en-PL/business",
    component: _bd9c6df0,
    name: "business___en-PL"
  }, {
    path: "/en-PL/careers",
    component: _fb6f27c6,
    children: [{
      path: "",
      component: _41aeae40,
      name: "careers___en-PL"
    }, {
      path: "marketing-project-manager",
      component: _51573c60,
      name: "careers-marketing-project-manager___en-PL"
    }, {
      path: "onboarding-specialist",
      component: _c66fc89e,
      name: "careers-onboarding-specialist___en-PL"
    }, {
      path: "software-engineer-web-frontend",
      component: _6283d2c5,
      name: "careers-software-engineer-web-frontend___en-PL"
    }]
  }, {
    path: "/en-PL/contact",
    component: _936e0bf0,
    name: "contact___en-PL"
  }, {
    path: "/en-PL/news",
    component: _1214aaca,
    children: [{
      path: "",
      component: _29c090de,
      name: "news___en-PL"
    }, {
      path: "/en-PL/news/:slug",
      component: _2809ef96,
      name: "news-slug___en-PL"
    }]
  }, {
    path: "/en-PL/order",
    component: _7dd2ab94,
    name: "order___en-PL"
  }, {
    path: "/en-PL/press",
    component: _bb9976aa,
    name: "press___en-PL"
  }, {
    path: "/en-PL/prices",
    component: _3dc3cedc,
    children: [{
      path: "",
      component: _31732cd6,
      name: "prices___en-PL"
    }]
  }, {
    path: "/en-PL/pro-signup",
    component: _4e425d80,
    name: "pro-signup___en-PL"
  }, {
    path: "/en-PL/profile",
    component: _831f19de,
    children: [{
      path: "",
      component: _2e24a8d4,
      name: "profile___en-PL"
    }, {
      path: "payment-methods",
      component: _5b502aa0,
      name: "profile-payment-methods___en-PL"
    }, {
      path: "payment-methods/add",
      component: _5ef5d702,
      name: "profile-payment-methods-add___en-PL"
    }]
  }, {
    path: "/en-PL/referral",
    component: _45504e65,
    name: "referral___en-PL"
  }, {
    path: "/en-PL/services",
    component: _4066cab4,
    children: [{
      path: "",
      component: _90efe6ae,
      name: "services___en-PL"
    }, {
      path: ":city",
      component: _35db63e4,
      name: "services-city___en-PL"
    }, {
      path: ":city/:service",
      component: _1c4d40d7,
      name: "services-city-service___en-PL"
    }, {
      path: ":city/:service/:skill",
      component: _487b52e0,
      name: "services-city-service-skill___en-PL"
    }]
  }, {
    path: "/en-PL/signup",
    component: _18d7fa60,
    name: "signup___en-PL"
  }, {
    path: "/en-PL/tasks",
    component: _377d0314,
    children: [{
      path: "",
      component: _92d2570e,
      name: "tasks___en-PL"
    }, {
      path: "past-tasks",
      component: _3e546308,
      name: "tasks-past-tasks___en-PL"
    }, {
      path: ":orderId",
      component: _339438f3,
      name: "tasks-orderId___en-PL"
    }]
  }, {
    path: "/lt-LT/apie-mus",
    component: _eed7e216,
    name: "about___lt-LT"
  }, {
    path: "/lt-LT/business",
    component: _bd9c6df0,
    name: "business___lt-LT"
  }, {
    path: "/lt-LT/ikainiai",
    component: _3dc3cedc,
    children: [{
      path: "",
      component: _31732cd6,
      name: "prices___lt-LT"
    }]
  }, {
    path: "/lt-LT/karjera",
    component: _fb6f27c6,
    children: [{
      path: "",
      component: _41aeae40,
      name: "careers___lt-LT"
    }, {
      path: "marketing-project-manager",
      component: _51573c60,
      name: "careers-marketing-project-manager___lt-LT"
    }, {
      path: "onboarding-specialist",
      component: _c66fc89e,
      name: "careers-onboarding-specialist___lt-LT"
    }, {
      path: "software-engineer-web-frontend",
      component: _6283d2c5,
      name: "careers-software-engineer-web-frontend___lt-LT"
    }]
  }, {
    path: "/lt-LT/kontaktai",
    component: _936e0bf0,
    name: "contact___lt-LT"
  }, {
    path: "/lt-LT/naujienos",
    component: _1214aaca,
    children: [{
      path: "",
      component: _29c090de,
      name: "news___lt-LT"
    }, {
      path: "/lt-LT/naujienos/:slug",
      component: _2809ef96,
      name: "news-slug___lt-LT"
    }]
  }, {
    path: "/lt-LT/order",
    component: _7dd2ab94,
    name: "order___lt-LT"
  }, {
    path: "/lt-LT/paslaugos",
    component: _4066cab4,
    children: [{
      path: "",
      component: _90efe6ae,
      name: "services___lt-LT"
    }, {
      path: ":city",
      component: _35db63e4,
      name: "services-city___lt-LT"
    }, {
      path: ":city/:service",
      component: _1c4d40d7,
      name: "services-city-service___lt-LT"
    }, {
      path: ":city/:service/:skill",
      component: _487b52e0,
      name: "services-city-service-skill___lt-LT"
    }]
  }, {
    path: "/lt-LT/pro-registracija",
    component: _4e425d80,
    name: "pro-signup___lt-LT"
  }, {
    path: "/lt-LT/profile",
    component: _831f19de,
    children: [{
      path: "",
      component: _2e24a8d4,
      name: "profile___lt-LT"
    }, {
      path: "payment-methods",
      component: _5b502aa0,
      name: "profile-payment-methods___lt-LT"
    }, {
      path: "payment-methods/add",
      component: _5ef5d702,
      name: "profile-payment-methods-add___lt-LT"
    }]
  }, {
    path: "/lt-LT/referral",
    component: _45504e65,
    name: "referral___lt-LT"
  }, {
    path: "/lt-LT/registracija",
    component: _18d7fa60,
    name: "signup___lt-LT"
  }, {
    path: "/lt-LT/spaudai",
    component: _bb9976aa,
    name: "press___lt-LT"
  }, {
    path: "/lt-LT/tasks",
    component: _377d0314,
    children: [{
      path: "",
      component: _92d2570e,
      name: "tasks___lt-LT"
    }, {
      path: "past-tasks",
      component: _3e546308,
      name: "tasks-past-tasks___lt-LT"
    }, {
      path: ":orderId",
      component: _339438f3,
      name: "tasks-orderId___lt-LT"
    }]
  }, {
    path: "/lt-LT/tinklarastis",
    component: _7c634fea,
    children: [{
      path: "",
      component: _3eaa8226,
      name: "blog___lt-LT"
    }, {
      path: "/lt-LT/tinklarastis/:slug",
      component: _4217c4b6,
      name: "blog-slug___lt-LT"
    }]
  }, {
    path: "/lt-PL/about",
    component: _eed7e216,
    name: "about___lt-PL"
  }, {
    path: "/lt-PL/blog",
    component: _7c634fea,
    children: [{
      path: "",
      component: _3eaa8226,
      name: "blog___lt-PL"
    }, {
      path: "/lt-PL/blog/:slug",
      component: _4217c4b6,
      name: "blog-slug___lt-PL"
    }]
  }, {
    path: "/lt-PL/business",
    component: _bd9c6df0,
    name: "business___lt-PL"
  }, {
    path: "/lt-PL/careers",
    component: _fb6f27c6,
    children: [{
      path: "",
      component: _41aeae40,
      name: "careers___lt-PL"
    }, {
      path: "marketing-project-manager",
      component: _51573c60,
      name: "careers-marketing-project-manager___lt-PL"
    }, {
      path: "onboarding-specialist",
      component: _c66fc89e,
      name: "careers-onboarding-specialist___lt-PL"
    }, {
      path: "software-engineer-web-frontend",
      component: _6283d2c5,
      name: "careers-software-engineer-web-frontend___lt-PL"
    }]
  }, {
    path: "/lt-PL/contact",
    component: _936e0bf0,
    name: "contact___lt-PL"
  }, {
    path: "/lt-PL/news",
    component: _1214aaca,
    children: [{
      path: "",
      component: _29c090de,
      name: "news___lt-PL"
    }, {
      path: "/lt-PL/news/:slug",
      component: _2809ef96,
      name: "news-slug___lt-PL"
    }]
  }, {
    path: "/lt-PL/order",
    component: _7dd2ab94,
    name: "order___lt-PL"
  }, {
    path: "/lt-PL/paslaugos",
    component: _4066cab4,
    children: [{
      path: "",
      component: _90efe6ae,
      name: "services___lt-PL"
    }, {
      path: ":city",
      component: _35db63e4,
      name: "services-city___lt-PL"
    }, {
      path: ":city/:service",
      component: _1c4d40d7,
      name: "services-city-service___lt-PL"
    }, {
      path: ":city/:service/:skill",
      component: _487b52e0,
      name: "services-city-service-skill___lt-PL"
    }]
  }, {
    path: "/lt-PL/press",
    component: _bb9976aa,
    name: "press___lt-PL"
  }, {
    path: "/lt-PL/prices",
    component: _3dc3cedc,
    children: [{
      path: "",
      component: _31732cd6,
      name: "prices___lt-PL"
    }]
  }, {
    path: "/lt-PL/pro-signup",
    component: _4e425d80,
    name: "pro-signup___lt-PL"
  }, {
    path: "/lt-PL/profile",
    component: _831f19de,
    children: [{
      path: "",
      component: _2e24a8d4,
      name: "profile___lt-PL"
    }, {
      path: "payment-methods",
      component: _5b502aa0,
      name: "profile-payment-methods___lt-PL"
    }, {
      path: "payment-methods/add",
      component: _5ef5d702,
      name: "profile-payment-methods-add___lt-PL"
    }]
  }, {
    path: "/lt-PL/referral",
    component: _45504e65,
    name: "referral___lt-PL"
  }, {
    path: "/lt-PL/signup",
    component: _18d7fa60,
    name: "signup___lt-PL"
  }, {
    path: "/lt-PL/tasks",
    component: _377d0314,
    children: [{
      path: "",
      component: _92d2570e,
      name: "tasks___lt-PL"
    }, {
      path: "past-tasks",
      component: _3e546308,
      name: "tasks-past-tasks___lt-PL"
    }, {
      path: ":orderId",
      component: _339438f3,
      name: "tasks-orderId___lt-PL"
    }]
  }, {
    path: "/pl-LT/about",
    component: _eed7e216,
    name: "about___pl-LT"
  }, {
    path: "/pl-LT/blog",
    component: _7c634fea,
    children: [{
      path: "",
      component: _3eaa8226,
      name: "blog___pl-LT"
    }, {
      path: "/pl-LT/blog/:slug",
      component: _4217c4b6,
      name: "blog-slug___pl-LT"
    }]
  }, {
    path: "/pl-LT/business",
    component: _bd9c6df0,
    name: "business___pl-LT"
  }, {
    path: "/pl-LT/careers",
    component: _fb6f27c6,
    children: [{
      path: "",
      component: _41aeae40,
      name: "careers___pl-LT"
    }, {
      path: "marketing-project-manager",
      component: _51573c60,
      name: "careers-marketing-project-manager___pl-LT"
    }, {
      path: "onboarding-specialist",
      component: _c66fc89e,
      name: "careers-onboarding-specialist___pl-LT"
    }, {
      path: "software-engineer-web-frontend",
      component: _6283d2c5,
      name: "careers-software-engineer-web-frontend___pl-LT"
    }]
  }, {
    path: "/pl-LT/contact",
    component: _936e0bf0,
    name: "contact___pl-LT"
  }, {
    path: "/pl-LT/news",
    component: _1214aaca,
    children: [{
      path: "",
      component: _29c090de,
      name: "news___pl-LT"
    }, {
      path: "/pl-LT/news/:slug",
      component: _2809ef96,
      name: "news-slug___pl-LT"
    }]
  }, {
    path: "/pl-LT/order",
    component: _7dd2ab94,
    name: "order___pl-LT"
  }, {
    path: "/pl-LT/press",
    component: _bb9976aa,
    name: "press___pl-LT"
  }, {
    path: "/pl-LT/prices",
    component: _3dc3cedc,
    children: [{
      path: "",
      component: _31732cd6,
      name: "prices___pl-LT"
    }]
  }, {
    path: "/pl-LT/pro-signup",
    component: _4e425d80,
    name: "pro-signup___pl-LT"
  }, {
    path: "/pl-LT/profile",
    component: _831f19de,
    children: [{
      path: "",
      component: _2e24a8d4,
      name: "profile___pl-LT"
    }, {
      path: "payment-methods",
      component: _5b502aa0,
      name: "profile-payment-methods___pl-LT"
    }, {
      path: "payment-methods/add",
      component: _5ef5d702,
      name: "profile-payment-methods-add___pl-LT"
    }]
  }, {
    path: "/pl-LT/referral",
    component: _45504e65,
    name: "referral___pl-LT"
  }, {
    path: "/pl-LT/signup",
    component: _18d7fa60,
    name: "signup___pl-LT"
  }, {
    path: "/pl-LT/tasks",
    component: _377d0314,
    children: [{
      path: "",
      component: _92d2570e,
      name: "tasks___pl-LT"
    }, {
      path: "past-tasks",
      component: _3e546308,
      name: "tasks-past-tasks___pl-LT"
    }, {
      path: ":orderId",
      component: _339438f3,
      name: "tasks-orderId___pl-LT"
    }]
  }, {
    path: "/pl-LT/uslugi",
    component: _4066cab4,
    children: [{
      path: "",
      component: _90efe6ae,
      name: "services___pl-LT"
    }, {
      path: ":city",
      component: _35db63e4,
      name: "services-city___pl-LT"
    }, {
      path: ":city/:service",
      component: _1c4d40d7,
      name: "services-city-service___pl-LT"
    }, {
      path: ":city/:service/:skill",
      component: _487b52e0,
      name: "services-city-service-skill___pl-LT"
    }]
  }, {
    path: "/pl-PL/aktualnosci",
    component: _1214aaca,
    children: [{
      path: "",
      component: _29c090de,
      name: "news___pl-PL"
    }, {
      path: "/pl-PL/aktualnosci/:slug",
      component: _2809ef96,
      name: "news-slug___pl-PL"
    }]
  }, {
    path: "/pl-PL/blog",
    component: _7c634fea,
    children: [{
      path: "",
      component: _3eaa8226,
      name: "blog___pl-PL"
    }, {
      path: "/pl-PL/blog/:slug",
      component: _4217c4b6,
      name: "blog-slug___pl-PL"
    }]
  }, {
    path: "/pl-PL/business",
    component: _bd9c6df0,
    name: "business___pl-PL"
  }, {
    path: "/pl-PL/ceny",
    component: _3dc3cedc,
    children: [{
      path: "",
      component: _31732cd6,
      name: "prices___pl-PL"
    }]
  }, {
    path: "/pl-PL/contact",
    component: _936e0bf0,
    name: "contact___pl-PL"
  }, {
    path: "/pl-PL/kariery",
    component: _fb6f27c6,
    children: [{
      path: "",
      component: _41aeae40,
      name: "careers___pl-PL"
    }, {
      path: "marketing-project-manager",
      component: _51573c60,
      name: "careers-marketing-project-manager___pl-PL"
    }, {
      path: "onboarding-specialist",
      component: _c66fc89e,
      name: "careers-onboarding-specialist___pl-PL"
    }, {
      path: "software-engineer-web-frontend",
      component: _6283d2c5,
      name: "careers-software-engineer-web-frontend___pl-PL"
    }]
  }, {
    path: "/pl-PL/o-nas",
    component: _eed7e216,
    name: "about___pl-PL"
  }, {
    path: "/pl-PL/order",
    component: _7dd2ab94,
    name: "order___pl-PL"
  }, {
    path: "/pl-PL/pro-rejestracja",
    component: _4e425d80,
    name: "pro-signup___pl-PL"
  }, {
    path: "/pl-PL/profile",
    component: _831f19de,
    children: [{
      path: "",
      component: _2e24a8d4,
      name: "profile___pl-PL"
    }, {
      path: "payment-methods",
      component: _5b502aa0,
      name: "profile-payment-methods___pl-PL"
    }, {
      path: "payment-methods/add",
      component: _5ef5d702,
      name: "profile-payment-methods-add___pl-PL"
    }]
  }, {
    path: "/pl-PL/referral",
    component: _45504e65,
    name: "referral___pl-PL"
  }, {
    path: "/pl-PL/rejestracja",
    component: _18d7fa60,
    name: "signup___pl-PL"
  }, {
    path: "/pl-PL/tasks",
    component: _377d0314,
    children: [{
      path: "",
      component: _92d2570e,
      name: "tasks___pl-PL"
    }, {
      path: "past-tasks",
      component: _3e546308,
      name: "tasks-past-tasks___pl-PL"
    }, {
      path: ":orderId",
      component: _339438f3,
      name: "tasks-orderId___pl-PL"
    }]
  }, {
    path: "/pl-PL/uslugi",
    component: _4066cab4,
    children: [{
      path: "",
      component: _90efe6ae,
      name: "services___pl-PL"
    }, {
      path: ":city",
      component: _35db63e4,
      name: "services-city___pl-PL"
    }, {
      path: ":city/:service",
      component: _1c4d40d7,
      name: "services-city-service___pl-PL"
    }, {
      path: ":city/:service/:skill",
      component: _487b52e0,
      name: "services-city-service-skill___pl-PL"
    }]
  }, {
    path: "/pl-PL/wydanie",
    component: _bb9976aa,
    name: "press___pl-PL"
  }, {
    path: "/en-PL/o/:code?",
    component: _23b165d8,
    name: "o-code___en-PL"
  }, {
    path: "/en-PL/pay/:orderId?",
    component: _2fe48a19,
    name: "pay-orderId___en-PL"
  }, {
    path: "/lt-LT/o/:code?",
    component: _23b165d8,
    name: "o-code___lt-LT"
  }, {
    path: "/lt-LT/pay/:orderId?",
    component: _2fe48a19,
    name: "pay-orderId___lt-LT"
  }, {
    path: "/lt-PL/o/:code?",
    component: _23b165d8,
    name: "o-code___lt-PL"
  }, {
    path: "/lt-PL/pay/:orderId?",
    component: _2fe48a19,
    name: "pay-orderId___lt-PL"
  }, {
    path: "/pl-LT/o/:code?",
    component: _23b165d8,
    name: "o-code___pl-LT"
  }, {
    path: "/pl-LT/pay/:orderId?",
    component: _2fe48a19,
    name: "pay-orderId___pl-LT"
  }, {
    path: "/pl-PL/o/:code?",
    component: _23b165d8,
    name: "o-code___pl-PL"
  }, {
    path: "/pl-PL/pay/:orderId?",
    component: _2fe48a19,
    name: "pay-orderId___pl-PL"
  }, {
    path: "/en-PL/cart/:cartId?/:itemId?",
    component: _b386fe1e,
    name: "cart-cartId-itemId___en-PL"
  }, {
    path: "/en-PL/pro/:proId?/:name",
    component: _06bc4ff1,
    name: "pro-proId-name___en-PL"
  }, {
    path: "/lt-LT/cart/:cartId?/:itemId?",
    component: _b386fe1e,
    name: "cart-cartId-itemId___lt-LT"
  }, {
    path: "/lt-LT/pro/:proId?/:name",
    component: _06bc4ff1,
    name: "pro-proId-name___lt-LT"
  }, {
    path: "/lt-PL/cart/:cartId?/:itemId?",
    component: _b386fe1e,
    name: "cart-cartId-itemId___lt-PL"
  }, {
    path: "/lt-PL/pro/:proId?/:name",
    component: _06bc4ff1,
    name: "pro-proId-name___lt-PL"
  }, {
    path: "/pl-LT/cart/:cartId?/:itemId?",
    component: _b386fe1e,
    name: "cart-cartId-itemId___pl-LT"
  }, {
    path: "/pl-LT/pro/:proId?/:name",
    component: _06bc4ff1,
    name: "pro-proId-name___pl-LT"
  }, {
    path: "/pl-PL/cart/:cartId?/:itemId?",
    component: _b386fe1e,
    name: "cart-cartId-itemId___pl-PL"
  }, {
    path: "/pl-PL/pro/:proId?/:name",
    component: _06bc4ff1,
    name: "pro-proId-name___pl-PL"
  }, {
    path: "/o/:code?",
    component: _23b165d8,
    name: "o-code___en-LT"
  }, {
    path: "/pay/:orderId?",
    component: _2fe48a19,
    name: "pay-orderId___en-LT"
  }, {
    path: "/cart/:cartId?/:itemId?",
    component: _b386fe1e,
    name: "cart-cartId-itemId___en-LT"
  }, {
    path: "/pro/:proId?/:name",
    component: _06bc4ff1,
    name: "pro-proId-name___en-LT"
  }, {
    path: "/",
    component: _af682c8c,
    name: "index___en-LT"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
