import axios from 'axios';

export default (context, inject) => {
  // context.$config.WEB_API_URL
  const axiosClient = axios.create({
    baseURL: context.$config.WEB_API_URL,
    timeout: 10000,
  });
  inject('axios', axiosClient);
  context.$axios = axiosClient;
};
