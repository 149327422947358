<template>
  <Modal
    :is-open="modalOpen.open && modalOpen.target === 'languageModal'"
    @toggle="$store.dispatch('modal/toggle', { modal: 'languageModal' })"
  >
    <p class="f3 ma0 pv4">{{ $t('navigation.selectLanguage') }}</p>
    <ul class="list ma0 pa0">
      <li v-for="locale in availableLocales" :key="locale">
        <nuxt-link
          v-if="locale !== 'lt-PL'"
          :to="switchLocalePath(locale)"
          class="db black link pv3 fw5 f4"
          @click.native="selectLocale(locale)"
        >
          {{ $t(`languages.${locale.split('-')[0]}.long`) }}
        </nuxt-link>
      </li>
    </ul>
  </Modal>
</template>

<script>
import Modal from '~/components/Modal';
import utils from '~/plugins/utilities';

export default {
  components: {
    Modal,
  },
  props: {
    modalOpen: {
      type: Object,
      required: true,
    },
    availableLocales: {
      type: Array,
      required: true,
    },
  },
  methods: {
    selectLocale(locale) {
      // console.log('SELECT LANG', lang);
      utils.setLocale(this, locale);
      this.$store.dispatch('modal/closeAll');
    },
  },
};
</script>
