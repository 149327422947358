<template>
  <form class="get-app-form" novalidate="true" @submit.prevent="submitForm">
    <div v-if="error" class="flex items-center justify-center pa3 bg-light-red">
      <svg-icon name="icon-info-filled" class="icon-info-filled" />
      <span class="lh-title">{{ error }}</span>
    </div>
    <div
      v-if="success"
      class="flex items-center justify-center pa3 bg-washed-green dark-green"
    >
      <svg-icon name="icon-info-filled" class="icon-info-filled" />
      <span class="lh-title">{{ success }}</span>
    </div>
    <div v-if="!success" class="flex items-center">
      <PhoneInput v-model.number="phone" autofocus @code="handleCode" />
      <a
        type="submit"
        class="pointer dib white fw7 br-pill mr3 bg-purple submit-button"
        @click="submitForm"
      >
        {{ $t('home.submit') }}
      </a>
    </div>
    <HoneyField value="honey" />
  </form>
</template>

<script>
import PhoneInput from '~/components/PhoneInput';
import HoneyField from '~/components/HoneyField';
import events from '~/constants/events';
import Api from '~/services/api';

export default {
  components: {
    PhoneInput,
    HoneyField,
  },
  props: {
    center: Boolean,
  },
  data() {
    return {
      error: null,
      success: null,
      phone: null,
      honey: '',
      code: null,
    };
  },
  methods: {
    async submitForm() {
      if (this.validateForm()) {
        const { anonymousId } = this.$cookies.get('app') || {};
        const data = {
          phoneNumber: `+${this.code}${this.phone}`,
          locale: this.$i18n.locale.split('-')[0],
          anonymousId,
          referer: document.referrer,
          sourceId: this.$route.query.src || '',
        };
        try {
          if (this.$route.query.code) {
            await Api.claimReferralCode(this, this.$route.query.code, data);
          } else {
            await Api.downloadApp(this, data);
          }
          this.$analytics.trackEvent(events.EXECUTE_GET_APP, {
            referralCode: this.$route.query.code || '',
            source: this.$route.query.src || '',
            from: this.$route.path,
            medium: 'sms_form',
          });
          this.phone = null;
          this.success = this.$t('form.getTheApp.success');
        } catch (e) {
          if (e?.message === 'error.phoneNumber.invalid') {
            this.error = this.$t(e.message);
          } else {
            this.error = this.$t('common.errorOccurred');
          }
        }
        if (this.$route.query.agent) {
          data.agentRef = this.$route.query.agent;
        }
      }
    },
    validateForm() {
      if (this.honey !== '') {
        return false;
      }
      if (!this.phone) {
        this.error = this.$t('form.getTheApp.requiredPhone');
        return false;
      }
      this.error = null;
      return true;
    },
    handleCode(value) {
      this.code = value;
    },
  },
};
</script>
