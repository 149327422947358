import Api from '~/services/api';

export const actions = {
  async nuxtServerInit(store, context) {
    const auth = context.$cookies.get('auth');
    // console.log(app);
    if (auth && auth.token) {
      await store.dispatch('auth/login', { token: auth.token });
      try {
        const customer = await Api.getCustomer(context);
        if (!customer.isRegistered) {
          context.$cookies.remove('auth');
          await store.dispatch('auth/logout');
          context.redirect(`/`);
        } else {
          await store.dispatch('auth/register');
        }
        // console.log(customer);
      } catch (e) {
        context.$cookies.remove('auth');
        await store.dispatch('auth/logout');
        context.redirect(`/`);
        console.error(e);
      }
    }
  },
};
