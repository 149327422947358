import io from 'socket.io-client';

export default (context, inject) => {
  let socket;

  const socketio = () => {
    const authToken = context.store.state.auth.token;
    if (!authToken) {
      throw new Error(
        'Failed to obtain Socket.IO connection (auth token missing)'
      );
    }

    socket ||= io(context.$config.WEB_API_URL, {
      path: '/wapi/v1/socket.io',
      transports: ['websocket'],
      query: { authToken },
    });

    return {
      listeners: [],

      onMessage(listener) {
        this.listeners.push(listener);
        socket.on('message', listener);
        return this;
      },

      removeListeners() {
        for (const listener of this.listeners) {
          socket?.removeListener('message', listener);
        }
      },
    };
  };

  socketio.close = () => {
    if (socket) {
      socket.close();
      socket = undefined;
    }
  };

  inject('socketio', socketio);
};
