<template>
  <div class="mt0-l tl flex flex-grow-1">
    <form
      novalidate="true"
      class="flex flex-column flex-grow-1 auth-form"
      @submit.prevent="submitForm"
    >
      <div class="flex flex-column flex-grow-1 justify-center">
        <div class="pax20 h-100 justify-center items-center flex">
          <div style="max-width: 448px" class="w-100">
            <div class="tc f4 mb3">
              {{ $t('login.heading') }}
            </div>
            <Input
              v-model="phone"
              autofocus
              placeholder="login.phone.placeholder"
            >
              <template v-slot:prefix>
                <div
                  class="flex flex-row br mr3 pr3 b--light-gray pointer"
                  @click.stop="selectCode"
                >
                  <div>+{{ popValue || code }}</div>
                  <img
                    :src="require('~/assets/icons/ic_arrow_down.svg')"
                    style="flex-shrink: 0; width: 8px"
                    class="ml2 flex w-100"
                  />
                </div>
              </template>
            </Input>
          </div>
        </div>
      </div>
      <div class="bt b--light-gray flex justify-center flex-column">
        <ErrorStripe v-if="error">
          {{ error }}
        </ErrorStripe>
        <div class="pax20 flex justify-center">
          <Button
            style="max-width: 448px"
            :loading="loading"
            @press="submitForm"
          >
            {{ $t('common.continue') }}
          </Button>
        </div>
      </div>
      <HoneyField value="honey" />
    </form>
  </div>
</template>

<script>
import Input from '~/components/Input';
import HoneyField from '~/components/HoneyField';
import Button from '~/components/Button';
import ErrorStripe from '~/components/ErrorStripe';
import CountryCodes from '~/components/CountryCodes';
import VerifyForm from '~/components/VerifyForm';
import Api from '~/services/api';
import events from '~/constants/events';

export default {
  components: {
    Input,
    HoneyField,
    Button,
    ErrorStripe,
  },
  props: {
    popValue: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      error: null,
      success: null,
      phone: null,
      showSignup: false,
      showSecondFactor: false,
      loading: false,
      honey: '',
    };
  },
  created() {
    this.code = this.$i18n.locale.split('-')[1] === 'PL' ? '48' : '370';
  },
  methods: {
    selectCode() {
      this.$root.$emit('push-dialog', {
        component: CountryCodes,
        title: this.$t('home.search.mobileCountryOverlayText'),
      });
    },
    async submitForm() {
      if (this.validateForm()) {
        const data = {
          phone: `+${this.popValue || this.code}${this.phone.trim()}`,
          locale: this.$i18n.locale,
        };
        this.$emit('submission', data);
        this.loading = true;
        // console.log('SUBMIT', data);
        let preauth;
        try {
          preauth = await Api.smsVerify(this, {
            phoneNumber: data.phone,
            locale: this.$i18n.locale.split('-')[0],
          });
        } catch (e) {
          console.error(e);
          this.error = this.$t('common.errorOccurred');
          this.$sentry.captureException(e);
        }
        if (preauth) {
          this.$store.dispatch('auth/verify', { preauth });
          this.$root.$emit('push-dialog', {
            component: VerifyForm,
            title: this.$t('screen.verify.header'),
          });
        }
        this.loading = false;
      }
    },
    validateForm() {
      if (this.honey !== '') {
        return false;
      }
      this.$analytics.trackEvent(events.ENTER_PHONE_AUTH);
      if (!this.phone) {
        this.error = this.$t('form.getTheApp.requiredPhone');
        return false;
      }
      this.error = null;
      return true;
    },
  },
};
</script>

<style scoped>
.code-input {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 50px;
  background-color: #fff;
  border: solid 1px #dedddd;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}
@media screen and (min-width: 60em) {
  .form-container {
    max-width: 19rem;
  }
}
</style>
