<template>
  <div
    class="mobile-search"
    tabindex="-1"
    :class="{ 'is-open': isSearchOverlayOpen }"
  >
    <form
      class="ma0 pa0"
      role="search"
      autocomplete="off"
      novalidate
      @submit.prevent
      @keydown.enter.prevent
    >
      <div class="relative mobile-search-container">
        <label
          class="relative flex items-center bg-white br-pill mobile-service-label"
          :for="
            heroSearchMobile ? 'hero-service-input' : 'header-service-input'
          "
        >
          <span class="icon-search-container">
            <svg-icon name="icon-search" class="icon-search" />
          </span>
          <input
            :id="
              heroSearchMobile ? 'hero-service-input' : 'header-service-input'
            "
            ref="serviceInputRef"
            v-model="serviceInput"
            :placeholder="
              isSearchOverlayOpen
                ? $t('home.search.shortServiceInputPlaceholder')
                : $t('home.search.serviceInputPlaceholder')
            "
            class="input-reset db w-100 bn bg-transparent mobile-service-input"
            type="text"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            @click="onMobileServiceInputClick"
            @input="(event) => handleServiceSearch(event.target.value)"
          />
          <button
            v-show="serviceInput.length && isSearchOverlayOpen"
            class="button-reset absolute pa2 bn br-pill bg-light-gray mobile-clear-button"
            @click.prevent="onMobileClearSearchClick"
          >
            <svg-icon name="icon-close-small" class="icon-close-small" />
          </button>
        </label>
        <button
          class="button-reset mobile-cancel-button"
          @click.prevent="onMobileCancelSearchClick"
        >
          {{ $t('home.search.cancelButtonText') }}
        </button>
      </div>
    </form>
    <div
      v-show="isSearchOverlayOpen"
      v-scroll-lock="isSearchOverlayOpen"
      class="h-100 overflow-y-scroll bg-white mobile-results-container"
    >
      <div
        v-show="isSearchResultsEmpty"
        class="flex flex-column items-center h-100 w-100 mobile-no-results-container"
      >
        <svg-icon name="icon-search-results" class="icon-search-results" />
        <div class="flex-grow-1 mobile-no-results-text">
          <p class="db mv0 w-100 b">
            {{ $t('home.search.noResultsFoundByText') }} "{{ serviceInput }}"
          </p>
          <p class="db mv0 w-100">
            {{ $t('home.search.noResultsCorrectionText') }}
          </p>
          <div class="mt4">
            <svg-icon name="icon-help" class="icon-help" />
            <p class="dib ma0 mr1">
              {{ $t('home.search.noResultsHelpText') }}
            </p>
            <a
              class="dib ma0 link light-purple"
              href="mailto:support@discontract.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t('home.search.noResultsContactUsText') }}
            </a>
          </div>
        </div>
      </div>
      <ul v-show="!isSearchResultsEmpty" class="ma0 pa0 mobile-service-list">
        <li
          v-for="result in searchResults"
          :key="result.jobId || result.skillId || result.serviceId"
          class="flex flex-row justify-between w-100 mobile-service-list-item"
          @click.prevent="() => onMobileServiceItemClick({ item: result })"
        >
          <span
            class="db flex-grow-1"
            :class="{ mr1: result.skillId === undefined }"
          >
            {{ result.title }}
          </span>
          <span v-if="result.skillId === undefined" class="fw4">
            {{ $t('home.search.viewServiceCategoryText') }}
          </span>
        </li>
      </ul>
    </div>
    <div class="mobile-city-overlay" :class="{ 'is-open': isCityOverlayOpen }">
      <div class="flex justify-between items-center mobile-city-overlay-header">
        <button
          class="button-reset mobile-city-button-back"
          @click.prevent="onMobileBackToSearchClick"
        >
          <svg-icon name="icon-arrow-back" class="icon-arrow-back" />
        </button>
        <h4 class="ma0 mobile-city-header-title">
          {{ $t('home.search.mobileCityOverlayHeader') }}
        </h4>
        <button
          class="button-reset mobile-city-button-close"
          @click.prevent="onMobileCancelSearchClick"
        >
          <svg-icon name="icon-close" class="icon-close" />
        </button>
      </div>
      <div class="mobile-choose-city-text">
        <p class="pa0 ma0">{{ $t('home.search.mobileCityOverlayText') }}</p>
      </div>
      <div
        v-scroll-lock="isSearchOverlayOpen"
        class="h-100 overflow-y-scroll bg-white"
      >
        <ul class="ma0 pa0 mobile-city-list">
          <li
            v-for="(city, index) in locations[selectedCountry]"
            :key="city"
            class="flex flex-row w-100 mobile-city-list-item"
            @click.prevent="onMobileCityItemClick(selectedCountry, index)"
          >
            {{ $t(`cities.${selectedCountry}.${city}`) }}
          </li>
        </ul>
      </div>
      <!-- <div class="mobile-country-select">
        <div class="flex items-center">
          <span class="br-pill icon-container">
            <svg-icon
              :name="`icon-flag-${selectedCountry.toLowerCase()}`"
              class="icon-flag"
            />
          </span>
          <span class="mobile-selected-country">
            {{ $t(`countries.${selectedCountry}`) }}
          </span>
          <a
            class="dib no-underline underline-hover mobile-show-countries-link"
            href="#"
            @click.prevent="onMobileShowCountriesLinkClick"
          >
            {{ $t('common.showAllCountries') }}
          </a>
        </div>
      </div> -->
    </div>
    <div
      class="mobile-country-overlay"
      :class="{ 'is-open': isCountryOverlayOpen }"
    >
      <div
        class="flex justify-between items-center mobile-country-overlay-header"
      >
        <button
          class="button-reset mobile-country-button-back"
          @click.prevent="onMobileBackToCitiesClick"
        >
          <svg-icon name="icon-arrow-back" class="icon-arrow-back" />
        </button>
        <h4 class="ma0 mobile-country-header-title">
          {{ $t('home.search.mobileCountryOverlayHeader') }}
        </h4>
        <button
          class="button-reset mobile-country-button-close"
          @click.prevent="onMobileCancelSearchClick"
        >
          <svg-icon name="icon-close" class="icon-close" />
        </button>
      </div>
      <div class="mobile-choose-country-text">
        <p class="pa0 ma0">{{ $t('home.search.mobileCountryOverlayText') }}</p>
      </div>
      <div
        v-scroll-lock="isSearchOverlayOpen"
        class="h-100 overflow-y-scroll bg-white"
      >
        <ul class="ma0 pa0 mobile-country-list">
          <li
            v-for="(cities, country) in locations"
            :key="country"
            tabindex="0"
            class="flex flex-row w-100 pointer mobile-country-list-item"
            @click.prevent="() => onMobileCountryItemClick({ country })"
          >
            <span class="br-pill icon-container">
              <svg-icon
                :name="`icon-flag-${country.toLowerCase()}`"
                class="icon-flag"
              />
            </span>
            <span>{{ $t(`countries.${country}`) }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import searchMixin from '~/components/Search/searchMixin';
import utils from '~/plugins/utilities';

export default {
  name: 'SearchMobile',
  mixins: [searchMixin],
  props: {
    heroSearchMobile: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isSearchOverlayOpen: false,
      isCityOverlayOpen: false,
      isCountryOverlayOpen: false,
    };
  },
  methods: {
    onMobileServiceInputClick(event) {
      const headerHeight = 71;
      const currentRoute = utils.getCurrentRoute({ route: this.$route });
      const heroServiceInput = document.getElementById('hero-service-input');

      event.preventDefault();

      if (
        this.$screen.touch &&
        currentRoute === 'index' &&
        heroServiceInput !== null &&
        event.target.id === 'header-service-input'
      ) {
        heroServiceInput.click();
        return;
      }

      this.openSearchOverlay();
      this.$refs.serviceInputRef.focus();

      if (this.$screen.touch) {
        // Fix iOS input focus/keyboard open scroll
        setTimeout(() => window.scrollTo(0, -headerHeight), 50);
      }
    },
    onMobileClearSearchClick() {
      this.clearServiceSearch();
      this.$refs.serviceInputRef.focus();
    },
    onMobileCancelSearchClick() {
      this.clearServiceSearch();
      this.closeOverlays();
    },
    onMobileServiceItemClick({ item }) {
      this.openCityOverlay();
      this.selectedService = item;
      this.serviceInput = item.title;
    },
    onMobileCityItemClick(country, index) {
      this.closeCityOverlay();
      this.setSelectedCity({
        country,
        cityIndex: index,
      });
      this.handleNavigation();
    },
    onMobileBackToSearchClick(event) {
      this.closeCityOverlay();
    },
    onMobileShowCountriesLinkClick() {
      this.openCountryOverlay();
    },
    onMobileCountryItemClick({ country }) {
      this.closeCountryOverlay();
      this.selectedCountry = country;
    },
    onMobileBackToCitiesClick() {
      this.closeCountryOverlay();
    },
    openSearchOverlay() {
      this.isSearchOverlayOpen = true;
    },
    closeSearchOverlay() {
      this.isSearchOverlayOpen = false;
    },
    openCityOverlay() {
      this.isCityOverlayOpen = true;
    },
    closeCityOverlay() {
      this.isCityOverlayOpen = false;
    },
    openCountryOverlay() {
      this.isCountryOverlayOpen = true;
    },
    closeCountryOverlay() {
      this.isCountryOverlayOpen = false;
    },
    closeOverlays() {
      this.closeSearchOverlay();
      this.closeCityOverlay();
      this.closeCountryOverlay();
    },
  },
};
</script>
