<template>
  <div
    id="app"
    class="page"
    :class="[
      getCurrentPage(),
      getCurrentLanguage(),
      { 'page--mob-search': hasPageMobileSearch() },
    ]"
  >
    <Header
      :current-lang="currentLang"
      :is-authenticated="isAuthenticated"
      :handle-login="handleLogin"
      :handle-logout="handleLogout"
      :handle-get-the-app="handleGetTheApp"
      :has-header-search="hasHeaderSearch"
    />
    <client-only v-if="isHeaderSearchMobileEnabled()">
      <SearchMobileHeader
        v-if="!$screen.large && hasPageMobileSearch()"
        :has-header-search="hasHeaderSearch"
      />
    </client-only>
    <main id="content" class="main-container" role="main">
      <nuxt />
    </main>
    <Footer
      :ios-link="iosLink"
      :android-link="androidLink"
      :current-lang="currentLang"
      :current-country="currentCountry"
    />
    <MobileMenu
      :current-lang="currentLang"
      :is-authenticated="isAuthenticated"
      :handle-login="handleLogin"
      :handle-logout="handleLogout"
    />
    <CountryModal :modal-open="modalOpen" />
    <DownloadModal :modal-open="modalOpen" />
    <LanguageModal
      :modal-open="modalOpen"
      :available-locales="availableLocales"
    />
    <Dialog
      :is-dialog-open="isDialogOpen"
      :dialog-component="dialogComponent"
      :dialog-stack-length="dialogStackLength"
      :dialog-pop-value="dialogPopValue"
      :dialog-params="dialogParams"
      :dialog-title="dialogTitle"
      :pop-dialog="popDialog"
      :close-dialog="closeDialog"
    />
    <CookiesBanner
      :show-consent="showConsent"
      :update-consent="updateConsent"
    />
  </div>
</template>

<script>
import Header from '~/components/Header';
import Footer from '~/components/Footer';
import MobileMenu from '~/components/MobileMenu';
import CountryModal from '~/components/modals/CountryModal';
import DownloadModal from '~/components/modals/DownloadModal';
import LanguageModal from '~/components/modals/LanguageModal';
import Dialog from '~/components/Dialog';
import AuthForm from '~/components/AuthForm';
import CookiesBanner from '~/components/CookiesBanner';
import SearchMobileHeader from '~/components/Search/SearchMobileHeader';
import {
  IOS_APP_STORE,
  ANDROID_GOOGLE_PLAY,
  ANDROID_DIRECT_GOOGLE_PLAY,
  DYNAMIC_LINK_REFERRAL_DOWNLOAD,
} from '~/constants/links';
import events from '~/constants/events';
import utils from '~/plugins/utilities';

export default {
  components: {
    Header,
    Footer,
    MobileMenu,
    CountryModal,
    DownloadModal,
    LanguageModal,
    Dialog,
    CookiesBanner,
    SearchMobileHeader,
  },
  data() {
    return {
      showConsent: undefined,
      isDialogOpen: false,
      dialogComponent: undefined,
      dialogStackLength: 0,
      dialogPopValue: undefined,
      dialogParams: {},
      dialogTitle: '',
      hasHeaderSearch: false,
    };
  },
  computed: {
    events() {
      return events;
    },
    modalOpen() {
      return this.$store.state.modal;
    },
    isAuthenticated() {
      return this.$store.state.auth.isAuthenticated;
    },
    iosLink() {
      return IOS_APP_STORE;
    },
    androidLink() {
      return ANDROID_GOOGLE_PLAY;
    },
    androidDirectLink() {
      return ANDROID_DIRECT_GOOGLE_PLAY;
    },
    referralDynamicLink() {
      return DYNAMIC_LINK_REFERRAL_DOWNLOAD;
    },
    availableLocales() {
      return this.$i18n.locales
        .map((l) => l.code)
        .filter((i) => i.split('-')[1] === this.$i18n.locale.split('-')[1]);
    },
    currentLang() {
      return this.$i18n.locale.split('-')[0];
    },
    currentCountry() {
      return this.$i18n.locale.split('-')[1];
    },
  },
  created() {
    this.dialogStack = [];
  },
  mounted() {
    // download link event listener from child pages
    this.$root.$on('handle-download', this.handleGetTheApp);
    this.$root.$on('open-dialog', this.openDialog);
    this.$root.$on('push-dialog', this.pushDialog);
    this.$root.$on('pop-dialog', this.popDialog);
    this.$root.$on('close-dialog', this.closeDialog);
    this.$root.$on('toggle-header-search', this.toggleHeaderSearch);
    const { consent } = this.$cookies.get('cookie-preferences') || {};
    if (
      typeof consent === 'undefined' &&
      this.$route.name.split('-')[0] !== 'o'
    ) {
      // do not show consent in order info B2B screen
      this.showConsent = true;
    }
    window.addEventListener('beforeunload', () => this.$socketio.close());
    this.$analytics.trackEvent(events.LOAD_APP);
  },
  destroyed() {
    this.$root.$off('handle-download');
    this.$root.$off('open-dialog');
    this.$root.$off('close-dialog');
    this.$root.$off('toggle-header-search');
  },
  methods: {
    getCurrentPage() {
      return utils.getCurrentPage({ route: this.$route });
    },
    getCurrentLanguage() {
      return utils.getCurrentLanguage({ locale: this.$i18n.locale });
    },
    getLocaleForCountry(country) {
      // check if next user locale is in array
      const nextLocale = this.$i18n.locale.split('-')[0] + '-' + country;
      if (this.$i18n.locales.map((l) => l.code).includes(nextLocale)) {
        return nextLocale;
      }
      return 'en-' + country;
    },
    openDialog(dialog, dialogProps) {
      this.isDialogOpen = true;
      this.dialogStack = [dialog];
      this.displayDialog();
      this.onCloseDialog = dialogProps ? dialogProps.onClose : null;
    },
    displayDialog() {
      const dialog = this.dialogStack[this.dialogStack.length - 1];
      this.dialogTitle = dialog.title;
      this.dialogComponent = dialog.component;
      this.dialogParams = dialog.params;
      this.dialogStackLength = this.dialogStack.length - 1;
    },
    pushDialog(dialog) {
      this.dialogStack.push(dialog);
      this.displayDialog();
    },
    popDialog(value) {
      this.dialogStack.pop();
      this.dialogPopValue = value;
      this.displayDialog();
    },
    closeDialog(params) {
      this.isDialogOpen = false;
      this.dialogComponent = false;
      this.dialogParams = null;
      this.dialogStack = [];
      this.dialogStackLength = 0;
      if (this.onCloseDialog) {
        this.onCloseDialog(params);
      }
      this.onCloseDialog = undefined;
    },
    toggleHeaderSearch(isHeroSearchVisible) {
      this.hasHeaderSearch = !isHeroSearchVisible;
    },
    isHeaderSearchMobileEnabled() {
      return (
        this.$config.FT_HEADER_SEARCH_MOBILE &&
        (this.currentCountry === 'LT' ||
          (this.$config.FT_PL_ORDERS && this.currentCountry === 'PL'))
      );
    },
    hasPageMobileSearch() {
      const currentRoute = utils.getCurrentRoute({ route: this.$route });

      return (
        this.isHeaderSearchMobileEnabled() &&
        (currentRoute === 'index' || currentRoute === 'services')
      );
    },
    updateConsent(consent) {
      this.showConsent = false;
      this.$analytics.updateConsent(consent);
      this.$cookies.set(
        'cookie-preferences',
        { consent },
        {
          path: '/',
          maxAge: 60 * 60 * 24 * 7 * 4,
        }
      );
    },
    async handleGetTheApp(link) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // check if this should go to referral link or regular download
      if (/android/i.test(userAgent)) {
        await this.$analytics.trackEvent(events.EXECUTE_GET_APP, {
          from: this.$route.path,
          medium: 'google_play_link',
        });
        window.location.href =
          link === 'ref-open'
            ? this.referralDynamicLink
            : this.androidDirectLink;
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        await this.$analytics.trackEvent(events.EXECUTE_GET_APP, {
          from: this.$route.path,
          medium: 'app_store_link',
        });
        window.location.href =
          link === 'ref-open' ? this.referralDynamicLink : this.iosLink;
      } else {
        this.$store.dispatch('modal/toggle', { modal: 'downloadModal' });
      }
    },
    handleLogin() {
      this.$store.dispatch('modal/closeAll');
      this.openDialog({
        component: AuthForm,
        title: this.$t('navigation.login'),
      });
    },
    handleLogout() {
      this.$store.dispatch('modal/closeAll');
      this.$socketio.close();
      this.$cookies.remove('auth');
      this.$store.dispatch('auth/logout');
      this.$router.push(
        this.localePath({
          name: 'index',
        })
      );
    },
  },
};
</script>
