import slug from 'slug';
import locationsMap from '~/components/Search/locationsMap';
import utils from '~/plugins/utilities';
import api from '~/services/api';

const searchMixin = {
  data() {
    return {
      serviceInput: '',
      searchTerm: '',
      searchResults: [],
      searchData: [],
      searchOptions: {
        keys: ['title'],
        threshold: 0.4,
        minMatchCharLength: 3,
      },
      isSearchResultsEmpty: false,
      selectedService: {
        title: '',
      },
      locations: locationsMap,
      cityCount: locationsMap[this.$i18n.locale.split('-')[1]].length,
      selectedCountry: this.$i18n.locale.split('-')[1],
      selectedCity: null,
    };
  },
  async created() {
    const response = await api.getServices(this);
    const servicesWithSkills = {};
    const skillsWithJobs = {};
    const searchData = [];
    Object.values(response.skills).forEach((data) => {
      if (!data.hidden) {
        if (
          this.$i18n.locale.split('-')[1] === 'PL' &&
          !data.areaPriceStats?.warsaw
        ) {
          return;
        }
        servicesWithSkills[data.serviceId] = true;
        skillsWithJobs[data.id] = true;
        searchData.push({
          skillId: data.id,
          serviceId: data.serviceId,
          title: this.$loc(this, data.localizedTitle),
          areaPriceStats: data.areaPriceStats,
        });
      }
    });
    Object.values(response.jobs).forEach((data) => {
      const skill = response.skills[data.skillId];
      if (skillsWithJobs[data.skillId]) {
        searchData.push({
          jobId: data.id,
          skillId: data.skillId,
          serviceId: skill.serviceId,
          skillTitle: this.$loc(this, skill.localizedTitle),
          title: this.$loc(this, data.localizedTitle),
        });
      }
    });
    Object.values(response.services).forEach((data) => {
      if (!servicesWithSkills[data.id]) {
        return;
      }
      searchData.unshift({
        serviceId: data.id,
        title: this.$loc(this, data.localizedTitle),
      });
    });
    this.searchData = searchData;
    this.setInitialSearchResults();
  },
  watch: {
    $route(to, from) {
      this.setInitialCity();
    },
  },
  mounted() {
    this.setInitialCity();
  },
  computed: {
    currentLang() {
      return this.$i18n.locale.split('-')[0];
    },
  },
  methods: {
    setInitialCity() {
      const selectedCityCode = localStorage.getItem('selectedCity');
      const country = this.$i18n.locale.split('-')[1];
      const cityParam = this.$route.params.city;
      if (cityParam) {
        const code = utils.getCityCodeFromRouterParam(cityParam, this.$i18n);
        if (code) {
          this.selectedCity = `cities.${country}.${code}`;
        }
      } else if (
        selectedCityCode &&
        locationsMap[country].includes(selectedCityCode)
      ) {
        this.selectedCity = `cities.${country}.${selectedCityCode}`;
      } else {
        this.setSelectedCity({ cityIndex: 0 });
      }
    },
    getSlug(text) {
      return slug(text, { locale: this.currentLang });
    },
    getServiceTitle(serviceId) {
      return this.searchData.find((item) => item.serviceId === serviceId).title;
    },
    setSelectedCity({ country, cityIndex }) {
      country = country || this.$i18n.locale.split('-')[1];

      // console.log(locationsMap[country][cityIndex]);
      this.selectedCityCode = locationsMap[country][cityIndex];
      localStorage.setItem('selectedCity', this.selectedCityCode);
      this.selectedCity = `cities.${country}.${this.selectedCityCode}`;
    },
    getLocaleForCountry(country) {
      // check if next user locale is in array
      const nextLocale = `${this.$i18n.locale.split('-')[0]}-${country}`;
      if (this.$i18n.locales.map((l) => l.code).includes(nextLocale)) {
        return nextLocale;
      }
      return `en-${country}`;
    },
    handleServiceSearch(searchTerm) {
      if (searchTerm && searchTerm !== '') {
        if (this.$screen.large) {
          this.openServiceDropdown();
        } else {
          this.serviceInput = searchTerm;
        }

        this.$search(
          searchTerm,
          this.filterSearchByCity(this.searchData),
          this.searchOptions
        ).then((results) => {
          this.searchResults = results;
          this.isSearchResultsEmpty = results.length === 0;
        });
      } else {
        if (this.$screen.large) {
          this.closeServiceDropdown();
        } else {
          this.serviceInput = '';
          this.isSearchResultsEmpty = false;
        }

        this.searchResults = this.filterSearchByCity(this.searchData);
      }
    },
    handleNavigation() {
      let route = null;

      if (this.selectedService.skillId) {
        route = this.localePath({
          name: 'services-city-service-skill',
          params: {
            city: slug(this.$t(this.selectedCity)),
            service: slug(this.getServiceTitle(this.selectedService.serviceId)),
            skill: slug(
              this.selectedService.skillTitle || this.selectedService.title
            ),
          },
        });
      } else if (this.selectedService.serviceId) {
        route = this.localePath({
          name: 'services-city-service',
          params: {
            city: slug(this.$t(this.selectedCity)),
            service: slug(this.selectedService.title),
          },
        });
      } else {
        if (this.$screen.large) {
          this.$refs.serviceInputRef.focus();
        }

        return;
      }

      if (route) {
        if (this.$screen.large) {
          this.closeDropdowns();
        } else {
          this.closeOverlays();
        }
        this.$router.push(route);
      }
    },
    filterSearchByCity(results) {
      // console.log(this.selectedCity, results, this.selectedCityCode);
      // return results.filter(
      //   (r) =>
      //     r.skillId &&
      //     r.areaPriceStats &&
      //     r.areaPriceStats[this.selectedCityCode]
      // );
      return results;
    },
    setInitialSearchResults() {
      this.searchResults = this.filterSearchByCity(this.searchData);
    },
    clearServiceSearch() {
      this.serviceInput = '';
      this.selectedService = { title: '' };
      this.isSearchResultsEmpty = false;
      this.searchResults = this.filterSearchByCity(this.searchData);

      if (this.$screen.large) {
        this.hasServiceInputFocus = true;
        this.$refs.serviceInputRef.focus();
        this.openServiceDropdown();
      }
    },
  },
};

export default searchMixin;
