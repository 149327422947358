<template>
  <div class="mt0-l tl flex" style="flex-grow: 1">
    <form
      novalidate="true"
      class="flex flex-column"
      style="flex-grow: 1"
      @submit.prevent="submitForm"
    >
      <div class="flex flex-column flex-grow-1 justify-center">
        <div class="pax20 h-100 justify-center items-center flex">
          <div style="max-width: 448px" class="w-100">
            <div class="tc f4 mb3">
              {{ $t('screen.verify.label') }}
            </div>
            <Input
              v-model="code"
              autofocus
              placeholder="screen.verify.placeholder"
            />
          </div>
        </div>
      </div>
      <div class="bt b--light-gray flex justify-center flex-column">
        <ErrorStripe v-if="error">
          {{ error }}
        </ErrorStripe>
        <div class="pax20 flex justify-center">
          <Button
            style="max-width: 448px"
            :loading="loading"
            :disabled="(code || '').trim().length != 4"
            @press="submitForm"
          >
            {{ $t('common.continue') }}
          </Button>
        </div>
      </div>
      <HoneyField value="honey" />
    </form>
  </div>
</template>

<script>
import HoneyField from '~/components/HoneyField';
import SignupForm from '~/components/SignupForm';
import Input from '~/components/Input';
import Button from '~/components/Button';
import ErrorStripe from '~/components/ErrorStripe';
import Api from '~/services/api';
import events from '~/constants/events';

export default {
  components: {
    HoneyField,
    Input,
    Button,
    ErrorStripe,
  },
  data() {
    return {
      error: null,
      code: '',
      honey: '',
      loading: false,
    };
  },
  methods: {
    async submitForm() {
      if (this.validateForm()) {
        // get the token
        let token;
        this.loading = true;
        try {
          token = await Api.validatePreauth(this, {
            id: this.$store.state.auth.preauth,
            code: this.code,
            tokenType: 'discontract',
          });
          this.loading = false;
        } catch (e) {
          this.loading = false;
          this.error = this.$t('common.errorOccurred');
          this.$sentry.captureException(e);
          return;
        }

        // login user with token
        this.$cookies.set(
          'auth',
          { token },
          {
            path: '/',
            maxAge: 60 * 60 * 24 * 7 * 4,
          }
        );
        this.$store.dispatch('auth/login', { token });
        this.$analytics.trackEvent(events.LOGIN);

        // check if the user has registered
        const { isRegistered } = await Api.getCustomer(this);
        if (isRegistered) {
          // todo: redirect somewhere?
          this.$store.dispatch('auth/register');
          this.$root.$emit('close-dialog');
        } else {
          // show sign up form
          this.$root.$emit('push-dialog', {
            component: SignupForm,
            title: this.$t('screen.register.header'),
          });
        }
      }
    },
    validateForm() {
      if (this.honey !== '') {
        return false;
      }
      this.$analytics.trackEvent(events.ENTER_PIN_AUTH);
      this.error = null;
      return true;
    },
  },
};
</script>
