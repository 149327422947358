export const state = () => ({
  target: null,
  open: false,
  onClose: null,
  onNext: null,
});

export const mutations = {
  open(state, { modal, open, onClose, onNext }) {
    state.target = modal;
    state.open = open;
    state.onClose = onClose;
    state.onNext = onNext;
  },
};

export const actions = {
  toggle({ state, commit }, { modal }) {
    const open = !state.open;
    commit('open', { modal, open });
  },
  open({ commit }, { modal, onClose, onNext }) {
    commit('open', { modal, open: true, onClose, onNext });
  },
  close({ commit }, { modal }) {
    commit('open', { modal, open: false });
  },
  closeAll({ commit }) {
    commit('open', { modal: null, open: false });
  },
};
