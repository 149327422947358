export default {
  OPEN_ORDER_TERMS: 'open_order_terms',
  PRESS_SKILL: 'press_skill',
  PRESS_SERVICE_SKILL: 'press_service_skill',
  PRESS_RELATED_SKILL: 'press_related_skill',
  PRESS_SEARCH_RESULT: 'press_search_result',
  PRESS_SEARCH_SUGGESTION: 'press_search_suggestion',
  INPUT_SEARCH: 'input_search',
  PRESS_SHARE_REFERRAL_URL: 'press_share_referral_url',
  SHARE_REFERRAL_URL: 'share_referral_url',
  COPY_REFERRAL_CODE: 'copy_referral_code',
  PRESS_CLAIM_CODE: 'press_claim_code',
  EXECUTE_CLAIM_CODE: 'execute_claim_code',
  OPEN_PROMO_PAGE: 'open_promo_page',
  PRESS_HELP: 'press_help',
  SELECT_CITY: 'select_city',
  FINISH_REGISTRATION: 'finish_registration',
  SUBMIT_ORDER: 'submit_order',
  SUBMIT_WAITLIST: 'submit_waitlist',
  EXECUTE_GET_APP: 'execute_get_app',
  VIEW_PAGE: 'view_page',
  VIEW_SKILL_PAGE: 'view_skill_page',
  LOAD_APP: 'load_app',
  LOAD_AUTH: 'load_auth',
  PRESS_CITY: 'press_city',
  OPEN_NOTIFICATION: 'open_notification',
  OPEN_DYNAMIC_LINK: 'open_dynamic_link',
  PRESS_SUPPORT_CALL: 'press_support_call',
  PRESS_SUPPORT_CHAT: 'press_support_chat',
  UPDATE_REGISTRATION: 'update_registration',
  START_ORDER: 'start_order', // todo
  ACCEPT_TASK: 'accept_task', // todo
  REJECT_TASK: 'reject_task', // todo
  PAY_TASK: 'pay_task', // todo
  REVIEW_TASK: 'review_task', // todo
  SELECT_PRO_ORDER: 'select_pro_order', // todo
  CANCEL_ORDER: 'cancel_order', // todo
  COMPLETE_ORDER: 'complete_order', // todo
  ADD_CARD_INFO: 'add_card_info', // todo
  COMPLETE_CARD_SCA: 'complete_card_sca', // todo
  ADD_CARD: 'add_card', // todo
  COMPLETE_TASK: 'complete_task', // todo
  PRESS_CALL_TASK: 'press_call_task',
  PRESS_CHAT_TASK: 'press_chat_task',
  ENTER_PHONE_AUTH: 'enter_phone_auth',
  RESEND_PIN_AUTH: 'resend_pin_auth',
  ENTER_PIN_AUTH: 'enter_pin_auth',
  PRESS_ORDER: 'press_order_start',
  PRESS_PRO_PROFILE: 'press_pro_profile',
  // PRESS_ORDER_PRO: 'press_order_pro',
  LOGIN: 'login',
  ATTRIBUTE_VISIT: 'attribute_visit',
};
