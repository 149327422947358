import { cities } from 'discontract-js-shared';

const locationsMap = {
  LT: cities.filter((city) => city.country === 'LT').map((city) => city.code),
  PL: ['warsaw'],
  // LV: ['riga'],
  // EE: ['tallinn'],
  // PL: ['warsaw', 'krakow', 'gdansk', 'poznan', 'szczecin', 'wroclaw'],
  // UA: ['kiev', 'lviv'],
};

export default locationsMap;
