<template>
  <div>
    <label class="sweet" for="email"></label>
    <input
      v-model="value"
      class="sweet"
      autocomplete="off"
      type="email"
      name="email"
      placeholder="Your e-mail here"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.sweet {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}
</style>
