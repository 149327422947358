import slug from 'slug';

function setLocale({ $cookies }, locale) {
  localStorage.setItem('selectedLanguage', locale.split('-')[0]);
  $cookies.set('selectedLocale', locale, {
    path: '/',
    maxAge: 60 * 60 * 24 * 7 * 4,
  });
  console.log('set locale', locale);
}

// TODO Refactor getCurrentPage and getCurrentRoute
function getCurrentPage({ route }) {
  if (route && route.name) {
    if (route.name.includes('skill')) {
      return 'page--skill';
    }

    const routeName = route.name.split('-')[0].split('___')[0];
    return `page--${routeName}`;
  }
}

function getCurrentRoute({ route }) {
  if (route && route.name) {
    if (route.name.includes('skill')) {
      return 'skill';
    }

    return route.name.split('-')[0].split('___')[0];
  }
}

function getCityCodeFromRouterParam(cityParam, { locale, messages }) {
  const lang = locale.split('-')[0];
  const country = locale.split('-')[1];
  const cities = messages[locale].cities[country];
  // get city key from translated param
  let cityKey;
  Object.keys(cities).forEach((key) => {
    if (slug(cities[key], { locale: lang }) === cityParam) {
      cityKey = key;
    }
  });
  return cityKey;
}

function getCurrentLanguage({ locale }) {
  if (locale) {
    return `page--${locale.split('-')[0]}`;
  }
}

function getHelpLinkLanguage({ currentLang }) {
  if (currentLang !== 'ru') {
    return `${currentLang}/`;
  }
  return '';
}

function matchBySlug(array, param, app) {
  // get all
  // console.log('MATCH BY SLUG', array, param);
  let matched;
  const lang = app.i18n.locale.split('-')[0];
  array.forEach((item) => {
    const translation = item.localizedTitle[lang];
    if (!translation) {
      return;
    }
    // sluggify and match translated title
    const sluggify = slug(translation, {
      locale: lang,
    });
    if (sluggify === param) {
      // return service if slug matches
      matched = item;
    }
  });
  return matched;
}

export default {
  getCurrentPage,
  getCurrentRoute,
  getCurrentLanguage,
  getHelpLinkLanguage,
  getCityCodeFromRouterParam,
  setLocale,
  matchBySlug,
};
