<template>
  <div class="relative w-100">
    <Dropdown
      :default-class="defaultClass"
      :default="defaultCountryCode"
      :options="supportedCountryCodes"
      class="absolute country-dropdown"
      @code="handleCode"
    />
    <input
      ref="phone"
      :value="value"
      class="input-reset outline-0 dib w-100 br-pill phone-input"
      type="number"
      @input="
        $emit('input', $event.target.value !== '' ? $event.target.value : null)
      "
    />
  </div>
</template>

<script>
import Dropdown from '~/components/CountryDropdown';
import { PHONE_CODES } from '~/constants/phoneCodes';

export default {
  components: {
    Dropdown,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    code: {
      type: Number,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    defaultCountry() {
      return this.$i18n.locale.split('-')[1];
    },
    defaultClass() {
      return 'flag-icon flag-icon-' + this.defaultCountry;
    },
    supportedCountryCodes() {
      return PHONE_CODES.map((item) => {
        return {
          code: item.iso2,
          value: item.dialCode,
          text: item.name,
        };
      });
    },
    defaultCountryCode() {
      return PHONE_CODES.find((item) => item.iso2 === this.defaultCountry)
        .dialCode;
    },
  },
  mounted() {
    this.$nextTick(() => this.autofocus && this.$refs.phone.focus());
    this.$emit('code', this.defaultCountryCode);
  },
  methods: {
    handleCode(value) {
      this.$emit('code', value);
    },
  },
};
</script>

<style scoped>
.phone-input {
  padding-left: 7rem;
  height: 50px;
  background-color: #fff;
  border: solid 1px #dedddd;
}
.country-dropdown {
  top: 13px;
  left: 21px;
  padding-right: 5px;
  border-right: solid 1px #dedddd;
  z-index: 99;
}
</style>
