<template>
  <div
    v-observe-visibility="
      heroSearchLarge
        ? { callback: visibilityChanged, intersection: intersectionOptions }
        : false
    "
    class="large-search"
  >
    <form
      v-click-outside="closeDropdowns"
      class="ma0 pa0 db br-pill bg-white"
      role="search"
      autocomplete="off"
      novalidate
      @submit.prevent
    >
      <div
        class="relative db flex items-center w-100 bg-white search-container"
        :class="{
          'is-service-input-active': isServiceInputActive && !isCityInputActive,
          'has-service-input-focus':
            hasServiceInputFocus && isServiceDropdownOpen,
          'is-city-input-active': isCityInputActive,
        }"
      >
        <div
          class="relative service-container"
          :class="{ 'is-open': isServiceDropdownOpen }"
        >
          <div class="flex db-l items-center w-100 br-pill">
            <div
              class="br-pill service-query"
              :class="{
                'is-active': serviceInput.length,
                'has-focus': serviceInput.length && isServiceDropdownOpen,
              }"
            >
              <label class="db service-label" for="service-input">
                <span class="dn db-l service-text">{{
                  $t('home.search.serviceInputLabel')
                }}</span>
                <input
                  id="service-input"
                  ref="serviceInputRef"
                  v-model="serviceInput"
                  tabindex="0"
                  :placeholder="$t('home.search.serviceInputPlaceholder')"
                  class="input-reset db w-100 bn bg-transparent service-input"
                  type="text"
                  autocorrect="off"
                  spellcheck="false"
                  autocomplete="off"
                  @keydown="onServiceInputKeyDown"
                  @focus="onServiceInputFocus"
                  @blur="onServiceInputBlur"
                  @click="onServiceInputClick"
                  @input="(event) => handleServiceSearch(event.target.value)"
                />
                <button
                  v-show="serviceInput.length && !isCityInputActive"
                  ref="clearSearchButtonRef"
                  tabindex="0"
                  class="button-reset absolute pa2 bn br-pill bg-light-gray clear-button"
                  @click.prevent="clearServiceSearch"
                >
                  <svg-icon name="icon-close-small" class="icon-close-small" />
                </button>
              </label>
            </div>
          </div>
        </div>
        <div class="dn flex-l search-separator"></div>
        <div
          class="relative dn db-l br-pill city-container"
          :class="{ 'is-open': isCityDropdownOpen }"
        >
          <div class="br-pill city-query">
            <label class="db city-label" for="city-input">
              <span class="db city-text">
                {{ $t('home.search.cityInputLabel') }}
              </span>
              <input
                id="city-input"
                ref="cityInputRef"
                :value="$t(selectedCity)"
                tabindex="0"
                class="input-reset db w-100 bn bg-transparent city-input"
                type="text"
                :readonly="true"
                @keydown="onCityInputKeyDown"
                @focus="onCityInputFocus"
                @blur="onCityInputBlur"
                @click.prevent="onCityInputClick"
              />
            </label>
          </div>
          <div
            v-show="isCityDropdownOpen"
            tabindex="-1"
            class="absolute-l mt2 bg-white overflow-y-scroll city-dropdown"
          >
            <ul
              v-show="!isCountryListOpen"
              class="ma0 pa0 city-list"
              tabindex="-1"
              role="listbox"
            >
              <li
                v-for="(city, index) in locations[selectedCountry]"
                :key="city"
                ref="cityListRef"
                tabindex="0"
                class="flex flex-row w-100 pointer city-list-item"
                @click="onCityItemClick(selectedCountry, index)"
                @keydown="
                  (event) => {
                    onCityItemKeyDown(
                      event,
                      $t(`cities.${selectedCountry}.${city}`),
                      index
                    );
                  }
                "
              >
                {{ $t(`cities.${selectedCountry}.${city}`) }}
              </li>
            </ul>
            <div
              v-show="!isCountryListOpen && $config.FT_PL_ORDERS && false"
              class="country-list-container"
            >
              <div class="flex items-center">
                <span class="br-pill icon-container">
                  <svg-icon
                    :name="`icon-flag-${selectedCountry.toLowerCase()}`"
                    class="icon-flag"
                  />
                </span>
                <span class="selected-country">
                  {{ $t(`countries.${selectedCountry}`) }}
                </span>
                <a
                  ref="showCountriesLinkRef"
                  class="dib no-underline underline-hover show-countries-link"
                  href="#"
                  @click="onShowCountriesLinkClick"
                  @keydown="onShowCountriesLinkKeyDown"
                >
                  {{ $t('common.showAllCountries') }}
                </a>
              </div>
            </div>
            <ul
              v-show="isCountryListOpen"
              class="ma0 pa0 country-list"
              tabindex="-1"
              role="listbox"
            >
              <li
                v-for="(cities, country, index) in locations"
                :key="country"
                ref="countryListRef"
                tabindex="0"
                class="flex flex-row items-center w-100 pointer country-list-item"
                @click="(event) => onCountryItemClick(event, country, index)"
                @keydown="
                  (event) => onCountryItemKeyDown(event, country, index)
                "
              >
                <span class="br-pill icon-container">
                  <svg-icon
                    :name="`icon-flag-${country.toLowerCase()}`"
                    class="icon-flag"
                  />
                </span>
                <span>{{ $t(`countries.${country}`) }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="absolute-l dn db-l search-button-container">
          <button
            ref="searchButtonRef"
            tabindex="0"
            class="button-reset flex items-center justify-center bn search-button"
            @click.prevent="handleNavigation"
            @focus="onSearchButtonFocus"
          >
            <svg-icon name="icon-search" class="icon-search" />
            <span class="db search-button-text">
              {{ $t('home.search.searchButtonText') }}
            </span>
          </button>
        </div>
        <div
          v-show="isServiceDropdownOpen"
          class="mt2 bg-white overflow-y-scroll service-dropdown"
        >
          <div
            v-show="isSearchResultsEmpty"
            class="flex items-center w-100 search-no-results"
          >
            <svg-icon name="icon-search-results" class="icon-search-results" />
            <div class="flex-grow-1 search-no-results-text">
              <p class="db mv0 w-100 b">
                {{ $t('home.search.noResultsFoundByText') }} "{{
                  serviceInput
                }}"
              </p>
              <p class="db mv0 w-100">
                {{ $t('home.search.noResultsCorrectionText') }}
              </p>
              <div class="flex items-center mt4">
                <svg-icon name="icon-help" class="icon-help" />
                <p class="ma0 mr1">
                  {{ $t('home.search.noResultsHelpText') }}
                </p>
                <a
                  ref="supportLinkRef"
                  class="ma0 link search-support-link"
                  href="mailto:support@discontract.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  @keydown="onSupportLinkKeyDown"
                >
                  {{ $t('home.search.noResultsContactUsText') }}
                </a>
              </div>
            </div>
          </div>
          <ul
            v-show="!isSearchResultsEmpty"
            tabindex="-1"
            role="listbox"
            class="ma0 pa0 js-service-list service-list"
          >
            <li
              v-for="(result, index) in searchResults"
              :key="result.jobId || result.skillId || result.serviceId"
              tabindex="0"
              role="option"
              class="flex flex-row w-100 pointer service-list-item"
              @click="(event) => onServiceItemClick(event, result)"
              @keydown="(event) => onServiceItemKeyDown(event, result, index)"
            >
              <span class="db flex-grow-1">{{ result.title }}</span>
              <span v-if="result.skillId === undefined" class="fw4">
                {{ $t('home.search.viewServiceCategoryText') }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import searchMixin from '~/components/Search/searchMixin';
import locationsMap from '~/components/Search/locationsMap';

export default {
  name: 'SearchLarge',
  directives: {
    ClickOutside,
  },
  mixins: [searchMixin],
  props: {
    heroSearchLarge: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      hasServiceInputFocus: false,
      isServiceInputActive: false,
      isCityInputActive: false,
      isServiceDropdownOpen: false,
      isCityDropdownOpen: false,
      isCountryListOpen: false,
      intersectionOptions: {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      },
    };
  },
  methods: {
    visibilityChanged(isHeroSearchVisible) {
      this.$root.$emit('toggle-header-search', isHeroSearchVisible);
    },
    onServiceInputFocus() {
      if (this.serviceInput.length) {
        this.handleServiceSearch(this.serviceInput);
      }

      this.closeCityDropdown();
      this.hasServiceInputFocus = true;
      this.openServiceDropdown();
    },
    onServiceInputBlur() {
      if (this.serviceInput.length === 0) {
        this.hasServiceInputFocus = false;
      }
    },
    onServiceInputClick() {
      this.openServiceDropdown();

      if (this.serviceInput.length) {
        this.handleServiceSearch(this.serviceInput);
      }
    },
    onServiceInputKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }

      if (event.key === 'Escape') {
        this.clearServiceSearch();
      }

      if (event.key === 'Backspace' && this.serviceInput.length === 1) {
        this.clearServiceSearch();
      }

      if (event.shiftKey && event.key === 'Tab') {
        this.closeServiceDropdown();
      }

      if (!event.shiftKey && event.key === 'Tab' && this.serviceInput.length) {
        event.preventDefault();
        this.$refs.clearSearchButtonRef.focus();
      }

      if (
        this.searchResults.length === 0 &&
        (event.key === 'ArrowDown' || event.key === 'ArrowUp')
      ) {
        event.preventDefault();
        this.$refs.supportLinkRef.focus();
        return;
      }

      if (
        (event.key === 'ArrowDown' || event.key === 'ArrowUp') &&
        !this.isServiceDropdownOpen
      ) {
        event.preventDefault();
        this.hasServiceInputFocus = false;
        this.isSearchResultsEmpty = false;
        this.setInitialSearchResults();
        this.openServiceDropdown();
        return;
      }

      if (event.key === 'ArrowDown' && this.isServiceDropdownOpen) {
        event.preventDefault();
        this.hasServiceInputFocus = false;
        this.serviceInput = this.searchResults[0].title;
        this.selectedService = this.searchResults[0];
        this.$el
          .getElementsByClassName('js-service-list')
          .item(0)
          .firstElementChild.focus();
      }

      if (event.key === 'ArrowUp' && this.isServiceDropdownOpen) {
        event.preventDefault();
        this.hasServiceInputFocus = false;
        this.serviceInput = this.searchResults[
          this.searchResults.length - 1
        ].title;
        this.selectedService = this.searchResults[
          this.searchResults.length - 1
        ];
        this.$el
          .getElementsByClassName('js-service-list')
          .item(0)
          .lastElementChild.focus();
      }
    },
    onServiceItemClick(event, item) {
      event.preventDefault();
      event.stopPropagation();
      this.selectedService = item;
      this.serviceInput = item.title;
      this.isServiceInputActive = false;
      this.hasServiceInputFocus = false;
      this.closeServiceDropdown();
      this.$refs.cityInputRef.focus();
    },
    onServiceItemKeyDown(event, item, index) {
      if (
        event.key === 'ArrowDown' &&
        this.searchResults.length - 1 === index
      ) {
        event.preventDefault();
        this.$refs.serviceInputRef.focus();
      }

      if (event.key === 'ArrowDown' && this.searchResults.length - 1 > index) {
        event.preventDefault();
        this.serviceInput = this.searchResults[index + 1].title;
        this.$el
          .getElementsByClassName('js-service-list')
          .item(0)
          .children[index + 1].focus();
      }

      if (event.key === 'ArrowUp' && index === 0) {
        event.preventDefault();
        this.$refs.serviceInputRef.focus();
      }

      if (event.key === 'ArrowUp' && index !== 0) {
        event.preventDefault();
        this.serviceInput = this.searchResults[index - 1].title;
        this.$el
          .getElementsByClassName('js-service-list')
          .item(0)
          .children[index - 1].focus();
      }

      if (event.key === 'Escape') {
        this.clearServiceSearch();
        return;
      }

      if (event.shiftKey && event.key === 'Tab') {
        event.preventDefault();
        this.$refs.serviceInputRef.focus();
      }

      if (event.key === 'Enter' || (!event.shiftKey && event.key === 'Tab')) {
        event.preventDefault();
        this.selectedService = item;
        this.serviceInput = item.title;
        this.isServiceInputActive = false;
        this.hasServiceInputFocus = false;
        this.closeServiceDropdown();
        this.$refs.cityInputRef.focus();
        this.isCityInputActive = true;
        this.openCityDropdown();
      }
    },
    onCityInputFocus() {
      this.hasServiceInputFocus = false;
      this.closeServiceDropdown();
      this.isCityInputActive = true;
      this.openCityDropdown();
    },
    onCityInputBlur() {
      this.isCityInputActive = false;
    },
    onCityInputClick() {
      this.hasServiceInputFocus = false;
    },
    onCityInputKeyDown(event) {
      if (event.key === 'Enter' || event.key === 'Escape') {
        event.preventDefault();
        this.$refs.searchButtonRef.focus();
      }

      if (event.key === 'Tab' && !event.shiftKey) {
        event.preventDefault();
        this.$refs.searchButtonRef.focus();
      }

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        this.$refs.cityListRef[0].focus();
        this.setSelectedCity({
          country: this.selectedCountry,
          cityIndex: 0,
        });
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        this.$refs.cityListRef[this.cityCount - 1].focus();
        this.setSelectedCity({
          country: this.selectedCountry,
          cityIndex: this.cityCount - 1,
        });
      }
    },
    onCityItemClick(country, index) {
      this.setSelectedCity({
        country,
        cityIndex: index,
      });
      this.closeCityDropdown();
    },
    onCityItemKeyDown(event, city, index) {
      event.preventDefault();

      if (
        event.key === 'ArrowDown' &&
        this.$refs.cityListRef.length - 1 > index
      ) {
        this.$refs.cityListRef[index + 1].focus();
        this.setSelectedCity({
          country: this.selectedCountry,
          cityIndex: index + 1,
        });
      }

      if (
        event.key === 'ArrowDown' &&
        this.$refs.cityListRef.length - 1 === index
      ) {
        this.$nextTick(() => {
          this.$refs.showCountriesLinkRef.focus();
        });
      }

      if (event.key === 'ArrowUp' && index !== 0) {
        this.$refs.cityListRef[index - 1].focus();
        this.setSelectedCity({
          country: this.selectedCountry,
          cityIndex: index - 1,
        });
      }

      if (event.shiftKey && event.key === 'Tab') {
        this.$refs.serviceInputRef.focus();
      }

      if (event.key === 'Enter' || (!event.shiftKey && event.key === 'Tab')) {
        this.$refs.searchButtonRef.focus();
      }

      if (event.key === 'Escape') {
        this.$refs.searchButtonRef.focus();
        this.setSelectedCity({
          country: this.selectedCountry,
          cityIndex: 0,
        });
      }
    },
    onShowCountriesLinkClick(event) {
      event.preventDefault();
      event.stopPropagation();
      this.isCountryListOpen = true;
    },
    onShowCountriesLinkKeyDown(event) {
      event.preventDefault();
      event.stopPropagation();

      if (event.key === 'Escape') {
        this.closeCityDropdown();
        this.$refs.searchButtonRef.focus();
      }

      if (event.shiftKey && event.key === 'Tab') {
        this.$refs.serviceInputRef.focus();
      }

      if (!event.shiftKey && event.key === 'Tab') {
        this.closeCityDropdown();
        this.$refs.searchButtonRef.focus();
      }

      if (event.key === 'Enter') {
        this.isCountryListOpen = true;
        this.$nextTick(() => {
          this.$refs.countryListRef[0].focus();
        });
      }

      if (event.key === 'ArrowUp') {
        this.$nextTick(() => {
          this.$refs.cityListRef[this.cityCount - 1].focus();
        });
      }
    },
    onCountryItemClick(event, country, index) {
      event.preventDefault();
      event.stopPropagation();
      this.selectedCountry = country;
      this.isCountryListOpen = false;
      this.cityCount = locationsMap[country].length;
      this.setSelectedCity({ country, cityIndex: 0 });
      this.$nextTick(() => {
        this.$refs.countryListRef[0].focus();
      });
    },
    onCountryItemKeyDown(event, country, index) {
      event.preventDefault();

      if (event.key === 'ArrowUp' && index !== 0) {
        this.$refs.countryListRef[index - 1].focus();
      }

      if (
        event.key === 'ArrowDown' &&
        this.$refs.countryListRef.length - 1 > index
      ) {
        this.$refs.countryListRef[index + 1].focus();
      }

      if (event.key === 'Enter') {
        this.isCountryListOpen = false;
        this.cityCount = locationsMap[country].length;
        this.selectedCountry = country;
        this.setSelectedCity({ country, cityIndex: 0 });
        this.$nextTick(() => {
          this.$refs.cityListRef[0].focus();
        });
      }

      if (event.key === 'Escape') {
        this.isCountryListOpen = false;
        this.$nextTick(() => {
          this.$refs.cityListRef[0].focus();
        });
      }
    },
    onSupportLinkKeyDown(event) {
      if (
        event.key === 'Escape' ||
        event.key === 'ArrowUp' ||
        event.key === 'ArrowDown'
      ) {
        event.preventDefault();
        this.$refs.serviceInputRef.focus();
      }

      if (event.shiftKey && event.key === 'Tab') {
        event.preventDefault();
        this.$refs.serviceInputRef.focus();
      }

      if (!event.shiftKey && event.key === 'Tab') {
        event.preventDefault();
        this.$refs.clearSearchButtonRef.focus();
      }
    },
    onSearchButtonFocus() {
      this.closeCityDropdown();
    },
    openServiceDropdown() {
      this.isServiceDropdownOpen = true;
    },
    closeServiceDropdown() {
      this.isServiceDropdownOpen = false;
    },
    openCityDropdown() {
      this.isCityDropdownOpen = true;
    },
    closeCityDropdown() {
      this.isCountryListOpen = false;
      this.isCityDropdownOpen = false;
    },
    closeDropdowns() {
      this.closeServiceDropdown();
      this.closeCityDropdown();
    },
  },
};
</script>
