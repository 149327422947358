<template>
  <div
    class="b--light-gray ba input-wrapper relative"
    :class="
      label
        ? '' + getPositionClass()
        : 'flex items-center ' + getPositionClass()
    "
    @click="focusInput"
  >
    <div
      v-if="label"
      class="z-2 relative"
      style="
        color: #7b7979;
        margin-left: 2px;
        background-color: rgba(255, 255, 255, 0);
      "
      :style="
        focused || hasValue
          ? 'margin-top: 3px;font-size: 13px;'
          : 'margin-top: 11px'
      "
    >
      {{ $t(label) }}
    </div>
    <div class="flex items-center w-100">
      <slot name="prefix"></slot>
      <input
        ref="input"
        :value="value"
        :placeholder="$t(placeholder)"
        :type="type"
        class="input-reset outline-0 input bn br3"
        :class="label ? 'absolute w-80' : ''"
        :style="label ? 'top: 20px' : ''"
        @focusin="setFocused(true)"
        @focusout="setFocused(false)"
        @input="setValue($event)"
      />
      <slot name="suffix"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    position: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    prefix: {
      type: Object,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      focused: false,
      hasValue: false,
    };
  },
  computed: {},
  mounted() {
    this.$nextTick(() => this.autofocus && this.$refs.input.focus());
  },
  methods: {
    setFocused(val) {
      this.focused = val;
    },
    setValue($event) {
      this.$emit(
        'input',
        $event.target.value !== '' ? $event.target.value.trim() : null
      );
      this.hasValue = $event.target.value;
    },
    focusInput() {
      this.$refs.input.focus();
    },
    getPositionClass() {
      if (this.position === 'top') {
        return 'br3 br--top bb-0';
      } else if (this.position === 'middle') {
        return 'bb-0';
      } else if (this.position === 'bottom') {
        return 'br3 br--bottom';
      } else {
        return 'br3';
      }
    },
  },
};
</script>

<style scoped>
.input {
  /* height: 44px; */
  background-color: #fff;
}
.input-wrapper {
  height: 50px;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
}
::placeholder {
  color: #7b7979;
}
</style>
