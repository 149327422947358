<template>
  <Modal
    :is-open="modalOpen.open && modalOpen.target === 'countryModal'"
    @toggle="$store.dispatch('modal/toggle', { modal: 'countryModal' })"
  >
    <p class="f3 pv4 ma0">{{ $t('navigation.selectCountry') }}</p>
    <CityChooser show-countries modal-view />
  </Modal>
</template>

<script>
import Modal from '~/components/Modal';
import CityChooser from '~/components/CityChooser';

export default {
  components: {
    Modal,
    CityChooser,
  },
  props: {
    modalOpen: {
      type: Object,
      required: true,
    },
  },
};
</script>
